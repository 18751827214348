import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import TimeFormatter from '../newTimeformatter/TimeFormatter';
import SyncIcon from '@mui/icons-material/Sync';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import Hexapi from '../HexAPI/Hexapi';
import Swal from 'sweetalert2';
import axios from 'axios';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

var oldData = []

export default class Datatable extends Component {
    constructor() {
        super()
        this.audioRef = React.createRef()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '', showaudio: false, Status: false, oldData: '',
            rowuserid: '', joinus: '', origin: '', origindata: [], joindata: [], membership: false,
            windowwidth: window.innerWidth, showLoading: false, showCartModal: false, Cartdata: [],
            Orderid: -1, CartDataFound: false,
            removecolumns: ['userid', 'origin', 'usertype', 'contactnumber', 'emailaddress', 'useraddress', 'username', 'cifcharges', 'bankcharges']

        }
    }

    componentDidMount() {

        const updatedData = this.props.data.map((item) => (
            { status: item.status, ...item, ViewQuotation: item.ViewQuotation }
        ))
        this.setState({ rowData: updatedData, isDataFound: true })
        oldData = updatedData
        // this.setState({ rowData: this.props.data, isDataFound: true })
        // oldData = this.props.data
        this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })

        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]

        }
        Hexapi(obj1).then((res) => {
            console.warn(res);
            if (res[""][0]) {
                this.setState({ userorigin: res[""][0].origin, usertype: res[""][0].usertype })
            }
        });

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ApprovalForm_GetData]`,
            'queryArr': []
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            this.setState({ joindata: res['usertype'], origindata: res["origin"] });
        })

        window.addEventListener('resize', this.handleWindowSize);
    }


    handleWindowSize = () => {
        this.setState({ windowwidth: window.innerWidth });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSize);
    }


    CartgetData = (Id, row) => {
        this.setState({ showCartModal: true, showLoading: true })
        if (row) {
            if (row.origin == "domestic" || row.origin == "Domestic" || row.origin != undefined) {
                this.setState({ isDomestic: true })
            } else {
                this.setState({ isDomestic: false })

            }

        }
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Order_GetData] @OrderID='{0}'`,
            'queryArr': [`${Id}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            this.setState({ showLoading: false })
            if (res[""].length > 0) {
                let data = res[""];
                console.log(data)
                let totalprice = 0;
                let Quantities = [];
                data.map((s) => {
                    Quantities.push(s.qty);
                })
                console.log(totalprice)
                this.setState({ Totalprice: totalprice, Cartdata: res[""], CartDataFound: false })

            }
            else {
                this.setState({ CartDataFound: true })
            }
        })
    }


    getHeader(data) {
        const header = Object.keys(data[0]).filter(col => !this.state.removecolumns.includes(col));
        // console.log(header)
        return header.map((s) => {
            // console.log(heading)
            {



                // if (s !== "recid") {
                if (s == "registeredcontactnumber") {
                    return <th>Registered Contact Number</th>
                }

                return (
                    <th>{s}</th>


                )
            }
        })
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            // console.log(date)
            var transformdate = date.split(".")[0];
            // console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            // console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            //   console.log(visitorinfo)
            // console.log(this.state.visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                "-" +
                exitdate.ShiftMonth +
                "-" +
                exitdate.CurYear.toString().slice(-2);
            // console.log(newReverseDate);

            return newReverseDate;
        }
    }


    // refreshtable = () => {
    //     this.props.callback(true);
    // }




    getRowsData(data) {
        // console.log(data)
        var newTableData = [].concat(data)
        let newData = newTableData
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true
            })
        }
        return newData.map((row, i) => {
            return (
                <tr key={i} onClick={() => {
                    // this.setState({membership:true});
                    // this.props.onRowClick(row)
                    this.CartgetData(row.recid, row)
                    this.setState({ Orderid: row.recid })

                }} >

                    {
                        Object.keys(row).filter(col => !this.state.removecolumns.includes(col)).map((heading) => {
                            if (row[heading] == null) {
                                row[heading] = ''
                            }

                            if (typeof row[heading] != 'object' && heading != "userstatus" && heading != "ViewQuotation" && heading != "adminstatus" && heading != "status") {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                            }
                            else if (heading == "ViewQuotation") {
                                return (
                                    <td key={row}>
                                        <span onClick={(e) => { e.stopPropagation(); this.DownloadQuotation(sessionStorage.getItem("userid"), row.recid) }}>
                                            <img src="./pdf.png" alt="pdf Icon" style={{ height: '25px', width: '25px' }}></img>
                                        </span>
                                    </td>
                                )
                            }
                            else if (heading == "status") {
                                if (row["userstatus"] == 1 && row["adminstatus"] == 1) {
                                    return (
                                        <td key={row}>
                                            <span>Quotation</span>
                                        </td>
                                    )
                                }
                                else {
                                    return <td key={row} >Order</td>
                                }
                            }
                            else if (heading == "adminstatus") {

                                if (row["adminstatus"] == 0) {
                                    return (
                                        <td key={row}>
                                            <span style={{ color: 'red' }}>Pending</span>
                                        </td>
                                    )
                                } else {
                                    return (
                                        <td key={row}>
                                            <span style={{ color: 'green' }}>Approved</span>
                                        </td>
                                    )
                                }
                            }
                            else if (heading == "userstatus") {
                                if (row["userstatus"] == 0 && row["adminstatus"] == 0) {
                                    return (
                                        <td key={row}>
                                            {/* <span onClick={(e) => { e.stopPropagation(); this.Approvedrequest(row["userid"], row['recid']); }}> <DoneIcon /> </span> */}
                                            <span style={{ backgroundColor: 'rgb(51 48 48)', color: 'white', padding: '3px', borderRadius: '7px', cursor: 'pointer' }} onClick={(e)=>{e.stopPropagation(); this.Approvedrequest(row["userid"], row['recid']); }}>Mark as Approved</span>
                                            {/* <span onClick={(e) => { e.stopPropagation();  this.Rejectrequest(row["recid"], row['recid']) }}> <CloseIcon />  </span> */}
                                        </td>
                                    )
                                }
                                // else if (row["userstatus"] == 1 && row["adminstatus"] == 0) {
                                //     return <td key={row} >Approved</td>
                                // }   
                                else {
                                    return <td key={row} >
                                        <span style={{ color: 'green' }}>
                                            Approved
                                        </span>
                                    </td>
                                }
                            }
                            else {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }

    DownloadQuotation = (userid, recid) => {
        console.log(userid, recid)

        let Arr = {
            "Userid": userid,
            "Orderid": recid
        }
        let fd = new FormData();
        fd.append('dataArray', JSON.stringify(Arr));

        axios({
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${sessionStorage.getItem('Apipathurl')}Quotationdownload.php`,
            data: fd,
            responseType: 'arraybuffer',
        })
            .then((res) => {
                console.log(res);
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(pdfBlob);
                link.download = `SeetechtechQuoation-${userid}-${recid}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })



        // axios({
        //     mode:'cors',
        //     method: 'GET',
        //     url: `https://hexbss.xyz/testenvironment/SeetechQuotations/${userid}-${recid}.pdf`,
        //     responseType: 'arraybuffer',
        // })
        //     .then((res) => {
        //         const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

        //         const link = document.createElement('a');
        //         link.href = URL.createObjectURL(pdfBlob);
        //         link.download = `SeetechtechQuoation-${userid}-${recid}`;

        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);
        //     })

    }

    Approvedrequest = (userid, recid) => {
        Swal.fire({
            text: " Are youm sure to Approve Order!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                container: 'custom-swal-container', // Add a custom class to the container
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Order_UserStatus]
                    @UserID='{0}',
                    @RecID ='{1}',
                    @Status='{2}'`,
                    'queryArr': [`${userid}`, `${recid}`, '1']
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                });
            }
        });
    }

    Rejectrequest = (userid) => {
        Swal.fire({
            text: " Are you want to Reject User!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    // 'query': `[dbo].[Seetech_Web_Proc_Order_GetData]  @UserID='{0}'`,
                    'queryArr': [`${userid}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                });
            }
        })
    }


    storeSearch(e) {
        // console.log(oldData)
        let value = e.target.value
        this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        // console.log(value)
        // console.log(applyFilter)
        if (value == '') {
            if (oldData.length > 0) {
                console.log(oldData)
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {
            if ((oldData.filter((row) => {
                if (Array.isArray(applyFilter)) {
                    let StrArr = []
                    for (let i = 0; i < applyFilter.length; i++) {
                        StrArr.push(row[`${applyFilter[i]}`])
                        // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                        // console.log(StrArr)
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                } else {
                    return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                }
            })).length > 0) {
                this.setState({
                    rowData: (oldData.filter((row) => {
                        if (Array.isArray(applyFilter)) {
                            let StrArr = []
                            for (let i = 0; i < applyFilter.length; i++) {
                                StrArr.push(row[`${applyFilter[i]}`])
                                // console.log(StrArr)
                            }

                            return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                            // console.log(StrArr)
                        } else {
                            return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                            // console.log(StrArr)
                        }
                    }))
                    , isDataFound: true
                }, () => {
                    this.setState({ currentPaginationPage: 0 })
                })


            } else {
                this.setState({ isDataFound: false })
            }
        }
    }


    render() {
        // console.log(this.state.windowwidth)
        // const boxstyle = this.state.windowwidth < 482 ?
        //     {
        //         position: 'absolute',
        //         top: '50%',
        //         left: '50%',
        //         transform: 'translate(-50%, -50%)',
        //         width: '75%',
        //         bgcolor: 'background.paper',
        //         // border: '2px solid #000',
        //         borderRadius: '10px',
        //         boxShadow: 24,
        //         p: 4,
        //         margin: 'auto',
        //     } : {
        //         position: 'absolute',
        //         top: '50%',
        //         left: '50%',
        //         transform: 'translate(-50%, -50%)',
        //         width: 400,
        //         bgcolor: 'background.paper',
        //         // border: '2px solid #000',
        //         borderRadius: '10px',
        //         boxShadow: 24,
        //         p: 4,
        //     };
        // console.log(this.props.pagination)
        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}
                <div style={{ height: 'calc(78vh - 5px)' }}>


                    <div style={{ position: 'relative', height: "inherit" }}>
                        {
                            this.props.isNavbar &&
                            <div className="_tablenavbar">
                                <div className="_left">
                                    <span>{this.props.heading}</span>
                                </div>
                                <div className="_right">
                                    <Tooltip title="Refresh">
                                        <IconButton style={{ color: '#fff' }} onClick={() => { this.setState({ isDataFound: false }); this.props.callback(true); }} >
                                            <SyncIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <SyncIcon onClick={() => { this.props.callback(true); this.setState({ isDataFound: false }) }} /> */}

                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...' onChange={(e) => this.storeSearch(e)} />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            <Tooltip title="Open Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                    if (document.getElementById('searchBarFocus')) {
                                                        document.getElementById('searchBarFocus').focus()
                                                    }
                                                })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                    {/* <Tooltip title={'Filter'}>
                                    <IconButton style={{ color: '#fff' }}>
                                        <FilterAltIcon />
                                    </IconButton>
                                </Tooltip> */}
                                </div>
                            </div>
                        }
                        <Table id='_tableGrid' className={'table-hover'} responsive style={this.state.windowwidth < 625 ? { width: '200%' } : { width: '100%' }} >
                            <thead style={this.props.headStyle} >
                                {/* style={this.props.headStyle}} */}
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {

                                this.state.isDataFound ?
                                    this.state.rowData.length > 0
                                        ?
                                        <tbody>
                                            {
                                                this.getRowsData(this.state.rowData)
                                            }
                                        </tbody>
                                        :
                                        <tbody>
                                            <div style={{ marginTop: '15%' }}>
                                                <h3>No Data Found!</h3>
                                            </div>
                                        </tbody>
                                    :
                                    <tbody>
                                        <div style={{ marginTop: '15%' }}>
                                            <h3>
                                                <Spinner animation='border' style={{ color: '#f9b101' }} />
                                            </h3>
                                        </div>
                                    </tbody>

                            }
                        </Table>
                        {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter">

                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        }

                    </div>
                </div>

                <Modal id="documentmodal" fullscreen={this.state.windowwidth < 1000 ? true : false} size={"lg"} centered animation={true} show={this.state.showCartModal} onHide={() => this.setState({ showCartModal: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Items</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='CartShopModal' style={this.state.windowwidth < 1000 ? { maxHeight: '100%' } : {}}>
                            {

                                this.state.CartDataFound ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%', color: 'rgb(235 197 137)', minHeight: '200px' }}>
                                        <h3>No Data Found</h3>
                                    </div>
                                    :

                                    this.state.showLoading ?
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
                                            <h3>
                                                <Spinner animation='border' style={{ color: '#f9b101' }} />
                                            </h3>
                                        </div>

                                        :

                                        this.state.Cartdata.map((data, index) => {
                                            let images = data.images;
                                            images = images.split(',');
                                            return (

                                                <div className='CartItems' key={index}>
                                                    <div className='Cartimagestyle' >
                                                        <div style={{ width: '100%', display: 'flex' }}>
                                                            <img id="zoom1" src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt="image" className='CartImages'
                                                                style={this.state.windowwidth < 900 ? { width: '100%', height: '100%', margin: 'auto', cursor: 'pointer' } : { width: '80%', height: '80%', margin: 'auto', cursor: 'pointer' }} ></img>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '100%' }} >
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <h5 style={{ fontWeight: '400 !important' }}>{data.name}</h5>
                                                        </div>
                                                        <div>
                                                            <h6 style={{ fontSize: '16px', marginBottom: '0' }}>Description</h6>
                                                            <div>
                                                                <span style={{ color: '#817575', fontSize: '0.8rem', fontWeight: '500' }}>{data.description}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>by : {data.brand}</span>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', fontWeight: '600', fontSize: '1.3rem' }}>
                                                            {
                                                                this.state.usertype == undefined || this.state.usertype == null || this.state.usertype == "" ?
                                                                    null
                                                                    :
                                                                    this.state.userorigin.toLowerCase() === "domestic" ?
                                                                        this.state.usertype.toLowerCase() == "visitor" ?
                                                                            null
                                                                            :
                                                                            this.state.usertype.toLowerCase() == "retailer" ?
                                                                                (`₹`)
                                                                                :
                                                                                (`₹`)

                                                                        :
                                                                        this.state.usertype.toLowerCase() == "visitor" ?
                                                                            null
                                                                            : (
                                                                                `$`
                                                                            )
                                                            }
                                                            {data.price}
                                                            <span style={{ textDecoration: 'line-through', fontSize: '16px', }}>
                                                                {
                                                                    this.state.userorigin.toLowerCase() === "domestic" ? (
                                                                        this.state.usertype.toLowerCase() == "wholesaler" &&
                                                                        (
                                                                            ` ₹${Number(data.mrp).toFixed(2)}`
                                                                        )
                                                                    ) : null
                                                                }

                                                            </span>
                                                        </div>

                                                        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <span> Qty: {data.qty} </span>
                                                            </div>
                                                        </div>

                                                        <div style={{}}>
                                                            <span style={{ color: 'rgb(208, 132, 12)', fontWeight: 'bold' }}>In stock</span>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <span>Eligible for shipping</span>
                                                        </div>
                                                        {/* <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                                        <div className='quantitystyle' style={{ cursor: 'pointer' }}>
                                                            <span>Qty: </span>
                                                            <select name="cars" id="cars" style={{ border: 'none', backgroundColor: '#f1f1f1' }}
                                                                onChange={(e) => {
                                                                    // this.QuantityChange(data.recid, e.target.value);
                                                                }}
                                                            >

                                                                {data && data.moq !== undefined ?
                                                                    this.state.Optionquantity.map((s, i) => {
                                                                        const Qtydata = (i + 1) * data.moq;
                                                                        return (
                                                                            <>
                                                                                <option value={i + 1} selected={i + 1 == data.qty ? true : false}>{Qtydata} </option>
                                                                            </>
                                                                        )
                                                                    })
                                                                    :
                                                                    null
                                                                }
                                                            </select>
                                                        </div>
                                                        <div class="vl"></div>
                                                        <div>
                                                            <span style={{ fontSize: '0.8rem', cursor: 'pointer' }} onClick={() => { this.DeleteItemCart(data.recid) }}>Delete</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div style={this.state.windowwidth < 1000 ? { display: 'block', marginTop: '10px' } : { display: 'flex', gap: '20px', alignItems: 'center', fontSize: '0.7rem', marginTop: '10px' }}>
                                                        
                                                        <div style={{ cursor: 'pointer' }}>
                                                            <span>Service Charge : &nbsp;
                                                               
                                                                <input type='number'
                                                                    value={this.state.servicecharge[index] == undefined ? data.servicecharge : this.state.servicecharge[index]}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        if (newValue.length <= 3) {
                                                                            this.setState((prevState) => {
                                                                                // Create a new array with the updated element
                                                                                const updatedCIFCharges = [...prevState.servicecharge];
                                                                                updatedCIFCharges[index] = newValue;
                                                                                return { servicecharge: updatedCIFCharges };
                                                                            });

                                                                        }
                                                                    }}
                                                                    style={{ width: '20%', border: '2px solid #ababab', borderRadius: '5px' }}></input> %
                                                            </span>
                                                        </div>
                                                        {this.state.windowwidth < 1000 ? <div style={{ marginTop: '10px' }}></div> : <div class="vl"></div>}
                                                        <div style={{ cursor: 'pointer' }}>
                                                            <span>Packing Charge : &nbsp;
                                                            
                                                                <input type='number'
                                                                    value={this.state.packagingcharge[index] == undefined ? data.packingcharge : this.state.packagingcharge[index]}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        if (newValue.length <= 3) {
                                                                            this.setState((prevState) => {
                                                                                // Create a new array with the updated element
                                                                                const updatedCIFCharges = [...prevState.packagingcharge];
                                                                                updatedCIFCharges[index] = newValue;
                                                                                return { packagingcharge: updatedCIFCharges };
                                                                            });

                                                                        }
                                                                    }}
                                                                    style={{ width: '20%', border: '2px solid #ababab', borderRadius: '5px' }}></input> %
                                                            </span>

                                                        </div>
                                                        {this.state.windowwidth < 1000 ? <div style={{ marginTop: '10px' }}></div> : <div class="vl"></div>}
                                                        <div style={{ cursor: 'pointer' }}>
                                                            <span>Special Discount : &nbsp;
                                                                <input type='number'
                                                                    value={this.state.Specialdiscount[index] == undefined ? data.specialdiscount : this.state.Specialdiscount[index]}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value;
                                                                        if (newValue.length <= 3) {
                                                                            this.setState((prevState) => {
                                                                                // Create a new array with the updated element
                                                                                const updatedCIFCharges = [...prevState.Specialdiscount];
                                                                                updatedCIFCharges[index] = newValue;
                                                                                return { Specialdiscount: updatedCIFCharges };
                                                                            });
                                                                        }
                                                                    }}
                                                                    style={{ width: '20%', border: '2px solid #ababab', borderRadius: '5px' }}></input> %
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center', fontSize: '0.7rem', justifyContent: 'flex-end', marginTop: '10px' }}>
                                                        <div style={{ cursor: 'pointer' }}>
                                                            <span onClick={() => { this.ChangeProductdata(data.recid, index, data.servicecharge, data.packingcharge, data.specialdiscount, data.userid) }}> <DoneAllIcon /> </span>
                                                        </div>

                                                    </div> */}
                                                    </div>
                                                </div>

                                            )
                                        })
                            }
                        </div>

                    </Modal.Body>
                </Modal >

            </>

        )
    }
}
