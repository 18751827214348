import React, { useEffect, useState } from 'react'
import OrderDatatable from '../usefulcomps/table/OrderDatatable';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Link, useParams } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';


const OrderPanel = () => {
    const dispatch = useDispatch();
    const [isDataFound, setisDataFound] = useState(true)
    const [tabledata, settabledata] = useState([]);
    const [showLoading, setshowLoading] = useState(true)
    const [windowidth, setwindowwidth] = useState(window.innerWidth);


    useEffect(() => {
        dispatch({ type: 'SaveRoutes', payload: { route: "orderhistory" } });
        OpenAdminpanel();
        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
          }
      
          window.addEventListener('resize', handleWindowsize);
          return () => {
            window.removeEventListener('resize', handleWindowsize)
          }
    }, [])

    const getrowdata = () => {

    }

    const OpenAdminpanel = () => {
        // setisDataFound(false)
        setshowLoading(true)


        if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_Order_GetList] @UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj).then((res) => {
                console.log(res);
                if (res[""]) {
                    setisDataFound(true)
                    settabledata(res[""])
                    setshowLoading(false)
                }
            });

        }else {
            setshowLoading(false)
            setisDataFound(false)

        }
    }

    const callback = (value) => {
        console.log(value);
        settabledata([])
        setisDataFound(false)
        OpenAdminpanel();
    }


    return (
        <>
            <div style={{ width: '100%', height: '600px' }}>
                {
                    showLoading ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
                            <h3>
                                <Spinner  animation='border' style={{ color: '#f9b101' }} />
                            </h3>
                        </div>
                        :

                        isDataFound ?
                            <OrderDatatable
                                data={tabledata}
                                headStyle={{ textTransform: "Capitalize" }}
                                heading={"Order History"}
                                isNavbar={true}
                                pagination={{
                                    rowsPerPage: 10,
                                    rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                                }}
                                onRowClick={(e) => getrowdata(e)}
                                callback={(e) => callback(e)}
                            />
                            :
                            <div style={{ display: 'flex', height: '500px', alignItems: 'center', justifyContent: 'center' }}>
                                <Link to="/login" style={{ textDecoration: 'none' }} className='LoginItemstyle' ><h4 style={{ textAlign: 'center', color: 'white', textTransform: 'none !important' }} >Please Login </h4></Link>
                            </div>
                }
            </div>
        </>
    )
}

export default OrderPanel