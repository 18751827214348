// import Home from './Home';
import About2 from './Comp/About2';
import Cart from './Comp/Cart';
import Contact from './Comp/Contact';
import Shop from './Comp/Shop';
import Wishlist from './Comp/Wishlist';
import Layout from './Layout';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import OrderPanel from './Comp/OrderPanel';
import OrderHistory from './Comp/OrderHistory';
import SchemePanel from './Comp/SchemePanel';
import ProductPanel from './Comp/ProductPanel';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Homescreen.css'
import SignUp from './Comp/SignUp';
import ApprovalPanel from './Comp/ApprovalPanel';
import { useEffect, useState } from 'react';
import Hexapi from './usefulcomps/HexAPI/Hexapi';
import Products from './Comp/Products';
import TermsCond from './Comp/TermsCond';
import Privacypolicy from './Comp/Privacypolicy';
import Disclaimerpolicy from './Comp/Disclaimerpolicy';
import Warranty from './Comp/Warranty';
import { Provider } from 'react-redux';
import store from './Store';
import ErrorPage from './Comp/ErrorPage';
import { Helmet } from 'react-helmet';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Home from './Comp/Home';
// import Blog from './Comp/Blog';
import Blogpreview from './Comp/Blogpreview';
import Blogmerge from './Comp/Blogmerge';

// import { useDispatch, useSelector } from 'react-redux';
// sessionStorage.setItem('Apipathurl', 'https://hexbss.xyz/seetechparts/')
// sessionStorage.setItem('Apipathurl','https://hexbss.xyz/testenvironment/')
// sessionStorage.setItem('Apipathurl','https://trackhr.co.in/seetechparts/')
// sessionStorage.setItem('userid','1')
sessionStorage.setItem('Apipathurl', 'https://seetechparts.com/')

function App() {
  const [Userorigin, setUserorigin] = useState('');
  const [usertype, setusertype] = useState('');
  const [currency, setcurrency] = useState('');
  const canonicalUrl = 'https://seetechparts.com/'
  // const dispatch = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem('Apipathurl') === undefined || sessionStorage.getItem('Apipathurl') == null || sessionStorage.getItem('Apipathurl') === "") {
      window.location.reload();
    }
    if (sessionStorage.getItem('userid') !== undefined && sessionStorage.getItem('userid') != null && sessionStorage.getItem('userid') !== "") {
      let obj1 = {
        'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
        'queryArr': [`${sessionStorage.getItem('userid')}`]
      }
      Hexapi(obj1).then((res) => {
        console.log(res[''][0])
        if (res[''][0]) {
          // dispatch({ type: 'CartItems', payload: { CartItems: res[''][0]?.totalcartitem } });
          setUserorigin(res[""][0].origin);
          setcurrency(res[''][0].currency)
          setusertype(res[""][0].usertype)
        }
      });

    }
  }, [])

  return (
    <>
      {/* <Homescreen/> */}
      {/* <RouterProvider router={router} /> */}
      {/* <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet> */}
      <Provider store={store}>
        <BrowserRouter >
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />}></Route>
              <Route path="/about" element={<About2 />}></Route>
              <Route path="/Blog" element={<Blogmerge />}></Route>
              <Route path="/Blog/:BlogID/:Title" element={<Blogpreview />}></Route>
              <Route path="/Shop/" element={<Shop />}></Route>
              {/* <Route path="/Shop/:FilterValue" element={<Shop />}></Route> */}
              <Route path="/Shop/:ProductId/" element={<Shop />}></Route>
              <Route path="/Product/:RecID/:suitableforpartreference/" element={<Products />}></Route>
              <Route path="/Cart" element={<Cart UOrigin={Userorigin} Utype={usertype} currency={currency} />}></Route>
              <Route path="/Wishlist" element={<Wishlist UOrigin={Userorigin} Utype={usertype} currency={currency} />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/Productpanel" element={<ProductPanel />}></Route>
              <Route path="/approvalpanel" element={<ApprovalPanel />}></Route>
              <Route path="/orderpanel" element={<OrderPanel />}></Route>
              <Route path="/orderhistory" element={<OrderHistory />}></Route>
              <Route path="/schemepanel" element={<SchemePanel />}></Route>
              <Route path="/login" element={<SignUp />}></Route>
              <Route path="/termsandconditions" element={<TermsCond />}></Route>
              <Route path="/privacypolicy" element={<Privacypolicy />}></Route>
              <Route path="/disclaimerpolicy" element={<Disclaimerpolicy />}></Route>
              <Route path="/warranty" element={<Warranty />}></Route>
              <Route path="*" element={<ErrorPage />}></Route>

            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
