import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import TimeFormatter from '../newTimeformatter/TimeFormatter';
import SyncIcon from '@mui/icons-material/Sync';
import { Modal, Spinner } from 'react-bootstrap';
import moment from 'moment';
import Hexapi from '../HexAPI/Hexapi';
import Swal from 'sweetalert2';
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoneIcon from '@mui/icons-material/Done';
// import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { autocompleteClasses } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Countrydata from '../../Comp/Countrydata';
import validator from 'validator'
import axios from 'axios';


var oldData = []

export default class Datatable extends Component {
    constructor() {
        super()
        this.audioRef = React.createRef()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '', showaudio: false, Status: false, oldData: '',
            rowuserid: '', joinus: '', origin: '', origindata: [], joindata: [], membership: false,
            windowwidth: window.innerWidth, schemedata: [], schemeid: -1, Editform: false, joinus2: '',
            origin2: '', schemeid2: -1, filterdata: ['originid', 'schemeid', 'usertypeid', 'currencyid'], defaultdata: [],
            CurrencyData: [], currencyid: -1, currencyid2: -1, name: '', lastname: '', Username: '', emailid: '',
            companyname: '', countrycode: '', mobilenumber: '', Password: '', confirmpassword: '', OpenAddScreen: false,
            showPassword: false, fireEditquery: false, ColumnData: [], Columnname: '',
        }
    }

    componentDidMount() {

        this.setState({ ColumnData: this.props.ColumnNames })
        let updatedData = this.props.data.map((item) => ({
            Edit: item.edit, ...item
        }))

        this.setState({ defaultdata: this.props.data })
        // console.log(updatedData)
        // let dummy = Object.keys(updatedData[0]).filter(column => !this.state.filterdata.includes(column))
        // console.warn(dummy)

        this.setState({ rowData: updatedData, isDataFound: true })
        oldData = updatedData;
        // this.setState({ rowData: this.props.data, isDataFound: true })
        // oldData = this.props.data
        this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ApprovalForm_GetData]`,
            'queryArr': []
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            this.setState({ joindata: res['usertype'], origindata: res["origin"], schemedata: res["scheme"], CurrencyData: res["currency"] });
        })

        window.addEventListener('resize', this.handleWindowSize);
    }


    handleWindowSize = () => {
        this.setState({ windowwidth: window.innerWidth });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSize);
    }



    getHeader(data) {
        const header = Object.keys(data[0])
        return header.filter(column => !this.state.filterdata.includes(column)).map((s) => {
            // console.log(heading)
            {
                if (s == "registeredcontactnumber") {
                    return <th>Registered Contact Number</th>
                }

                return (
                    <th>{s}</th>


                )
            }
        }
            // }
        )
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            // console.log(date)
            var transformdate = date.split(".")[0];
            // console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            // console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            //   console.log(visitorinfo)
            // console.log(this.state.visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                "-" +
                exitdate.ShiftMonth +
                "-" +
                exitdate.CurYear.toString().slice(-2);
            // console.log(newReverseDate);

            return newReverseDate;
        }
    }

    setphonenumber = (e) => {
        const newValue = e.target.value;

        if (newValue.length <= 15) {
            this.setState({ mobilenumber: newValue })
        }
    }



    getRowsData(data) {
        console.log(data)
        var newTableData = [].concat(data)
        let newData = newTableData
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true
            })
        }
        return newData.map((row, i) => {
            return (
                <tr key={i} onClick={() => {
                    this.props.onRowClick(row);
                }} >

                    {
                        Object.keys(row).filter(heading => !this.state.filterdata.includes(heading)).map((heading) => {

                            if (typeof row[heading] != 'object' && heading != "status" && heading != "Edit" && row[heading] != 'null') {
                                return <td key={heading} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                            }
                            else if (heading === "Edit") {
                                return <td key={row[heading]} >
                                    <div>
                                        <span onClick={(e) => { e.stopPropagation(); this.Editfun(row, i); this.setState({ rowuserid: row["userid"] }) }}>
                                            <EditIcon style={{ color: 'rgb(66 137 165)' }} />
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (row[heading] == null) {
                                return <td ></td>
                            }
                            else if (heading == "status") {
                                if (row["status"] == 0) {
                                    return (
                                        <td key={row[heading]}>
                                            <span onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({ membership: true, rowuserid: row["userid"], emailid: row.emailaddress,firstname:row.firstname,mobilenumber:row.contactnumber })

                                            }}> <DoneIcon style={{ color: 'green' }} /> </span>
                                            <span onClick={() => { this.Rejectrequest(row["userid"]) }} > <CloseIcon style={{ color: 'red' }} />  </span>
                                        </td>
                                    )
                                }
                                else if (row["status"] == 1) {
                                    return <td key={row[heading]} style={{ color: '#59a759' }} onClick={() => { this.Markasunapproved(row["userid"]) }}>Approved</td>
                                }
                                else {
                                    return <td key={row[heading]}>Rejected</td>
                                }
                            }
                            else {
                                // return <td >{this.setDate(row[heading].date) }</td>
                                // return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} /> 
                                return <td key={row[heading]} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }


    Editfun = (row, index) => {
        console.log(row)
        this.setState({
            Password: row.password, firstname: row.firstname, lastname: row.lastname, mobilenumber: row.contactnumber, emailid: row.emailaddress, Username: row.username, countrycode: row.countrycode,
            companyname: row.companyname, joinus2: row.usertypeid, origin2: row.originid, schemeid2: row.schemeid, Editform: true, currencyid2: row.currencyid
        });
    }

    Approvedrequest = (userid) => {

        if (this.state.joinus != "" && this.state.joinus != null) {
            if (this.state.origin != "" && this.state.origin != null) {
                if (this.state.schemeid != -1 && this.state.schemeid != "") {
                    if (this.state.currencyid != -1 && this.state.currencyid != "") {
                        Swal.fire({
                            text: " Do you want to Approve User!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes',
                            customClass: {
                                container: 'custom-swal-container', // Add a custom class to the container
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                let obj = {
                                    'query': `[dbo].[Seetech_Web_Proc_UserApproval_Approve]@UserID='{0}',@OriginID='{1}', @UserTypeID='{2}',@SchemeID='{3}',@CurrencyID='{4}'`,
                                    'queryArr': [`${userid}`, `${this.state.origin}`, `${this.state.joinus}`, `${this.state.schemeid}`, `${this.state.currencyid}`]
                                }
                                Hexapi(obj).then((res) => {
                                    console.log(res)

                                    let fd = new FormData();
                                    fd.append("EmailID", this.state.emailid);
                                    // fd.append('data', JSON.stringify(arr));
                                    // console.warn(fd);
                                    axios({
                                        mode: 'cors',
                                        method: "POST",
                                        // headers: { "Content-Type": "application/json" },
                                        headers: { "Content-Type": "multipart/form-data" },
                                        url: `https://seetechparts.com/AccountApproval.php`,
                                        data: fd,
                                    }).then((res) => {
                                        console.log(res.data);
                                        this.props.callback(true)
                                    });
                                });
                                const json = JSON.stringify({
                                    "apiKey": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmVhNCIsIm5hbWUiOiJTZWV0ZWNoIFBhcnRzIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1OTNhYTg5ZGZiOWNjN2FiODcyYmU5ZiIsImFjdGl2ZVBsYW4iOiJCQVNJQ19NT05USExZIiwiaWF0IjoxNzA0MTc2MjY1fQ.xsH0Pq97JwcuPgrYahyrgGs8NzZGOIX-wydm9XxLNW8",
                                    "campaignName": "user_approval_notification_new",
                                    "destination": this.state.mobilenumber,
                                    "userName": this.state.firstname,
                                    "templateParams": [this.state.firstname,this.state.firstname,this.state.mobilenumber,this.state.emailid]
                                });
                                axios({
                                                mode: 'cors',
                                                method: "POST",
                                                // headers: { "Content-Type": "application/json" },
                                                headers: { "Content-Type": "application/json" },
                                                url: `https://backend.aisensy.com/campaign/t1/api/v2`,
                                                data: json}).then((res)=>{
                                                    console.log(res);
                                                    // this.props.callback(true)
                                                })
                            }
                        });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please select Currency!',
                            customClass: {
                                container: 'custom-swal-container', // Add a custom class to the container
                            },
                        })
                    }
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select scheme!',
                        customClass: {
                            container: 'custom-swal-container', // Add a custom class to the container
                        },
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select Origin!',
                    customClass: {
                        container: 'custom-swal-container', // Add a custom class to the container
                    },
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select Joinus!',
                customClass: {
                    container: 'custom-swal-container', // Add a custom class to the container
                },
            })

        }

    }

    Rejectrequest = (userid) => {
        Swal.fire({
            text: " Are you want to reject this user ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_UserApproval_Reject]@UserID='{0}'`,
                    'queryArr': [`${userid}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                });
            }
        })
    }
    Markasunapproved = (userid) => {
        Swal.fire({
            text: " Are you want to unapprove this user ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_UserApproval_MarkasUnapproved]@UserID='{0}'`,
                    'queryArr': [`${userid}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                });
            }
        })
    }


    storeSearch(e) {
        console.log(e)
        let value = e
        this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        // console.log(value)
        // console.log(applyFilter)
        if (value == '') {
            if (oldData.length > 0) {
                console.log(oldData)
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        }
        else {
            if (this.state.Columnname != "") {
                // console.warn("columndata")
                let col = [];
                col.push(this.state.Columnname)
                if ((oldData.filter((row) => {
                    if (Array.isArray(col)) {
                        let StrArr = []
                        for (let i = 0; i < col.length; i++) {
                            StrArr.push(row[`${col[i]}`])
                            // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                            // console.log(StrArr)
                        }
                        return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                    } else {
                        return (oldData[`${col[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                    }
                })).length > 0) {
                    this.setState({
                        rowData: (oldData.filter((row) => {
                            if (Array.isArray(col)) {
                                let StrArr = []
                                for (let i = 0; i < col.length; i++) {
                                    StrArr.push(row[`${col[i]}`])
                                    // console.log(StrArr)
                                }

                                return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                                // console.log(StrArr)
                            } else {
                                return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                                // console.log(StrArr)
                            }
                        }))
                        , isDataFound: true
                    }, () => {
                        this.setState({ currentPaginationPage: 0 })
                    })


                } else {
                    this.setState({ isDataFound: false })
                }

            } else {
                if ((oldData.filter((row) => {
                    if (Array.isArray(applyFilter)) {
                        let StrArr = []
                        for (let i = 0; i < applyFilter.length; i++) {
                            StrArr.push(row[`${applyFilter[i]}`])
                            // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                            // console.log(StrArr)
                        }
                        return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                    } else {
                        return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                    }
                })).length > 0) {
                    this.setState({
                        rowData: (oldData.filter((row) => {
                            if (Array.isArray(applyFilter)) {
                                let StrArr = []
                                for (let i = 0; i < applyFilter.length; i++) {
                                    StrArr.push(row[`${applyFilter[i]}`])
                                    // console.log(StrArr)
                                }

                                return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                                // console.log(StrArr)
                            } else {
                                return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                                // console.log(StrArr)
                            }
                        }))
                        , isDataFound: true
                    }, () => {
                        this.setState({ currentPaginationPage: 0 })
                    })


                } else {
                    this.setState({ isDataFound: false })
                }
            }

        }
    }

    SubmitEditDetails = (userid) => {
        console.log(this.state.origin2, this.state.schemeid2);
        if (validator.isEmail(this.state.emailid)) {
            if (this.state.mobilenumber.length <= 15) {
                if (this.state.countrycode != '') {
                    if (this.state.Password != "") {
                        if (this.state.joinus2 != "" && this.state.joinus2 != null) {
                            if (this.state.origin2 != "" && this.state.origin2 != null) {
                                // if (this.state.schemeid2 != -1 && this.state.schemeid2 != "") {
                                if (this.state.currencyid2 != -1 && this.state.currencyid2 != "") {

                                    Swal.fire({
                                        text: " Are you want to Edit Details",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Yes',
                                        customClass: {
                                            container: 'custom-swal-container', // Add a custom class to the container
                                        },
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            let obj = {
                                                'query': `[dbo].[Seetech_Web_Proc_UserApproval_Edit]
                                                        @FirstName='{0}',@LastName='{1}',@ContactNumber='{2}',@EmailAddress='{3}', @Username='{4}', @Password='{5}', @CountryCode='{6}',@CompanyName='{7}',
                                                        @UserID='{8}',@OriginID='{9}', @UserTypeID='{10}',@SchemeID='{11}',@CurrencyID='{12}'`,
                                                'queryArr': [`${this.state.firstname}`, `${this.state.lastname}`, `${this.state.mobilenumber}`, `${this.state.emailid}`,
                                                `${this.state.Username}`, `${this.state.Password}`, `${this.state.countrycode}`, `${this.state.companyname}`, `${userid}`, `${this.state.origin2}`,
                                                `${this.state.joinus2}`, `${this.state.schemeid2}`, `${this.state.currencyid2}`]
                                            }
                                            Hexapi(obj).then((res) => {
                                                console.log(res)
                                                this.props.callback(true)
                                            });
                                        }
                                    });
                                }
                                else {
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Please select Currency!',
                                        customClass: {
                                            container: 'custom-swal-container', // Add a custom class to the container
                                        },
                                    })
                                }
                            }
                            // else {
                            //     Swal.fire({
                            //         icon: 'error',
                            //         title: 'Oops...',
                            //         text: 'Please select scheme!',
                            //         customClass: {
                            //             container: 'custom-swal-container', // Add a custom class to the container
                            //         },
                            //     })
                            // }
                            // }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Please select Origin!',
                                    customClass: {
                                        container: 'custom-swal-container', // Add a custom class to the container
                                    },
                                })
                            }
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Please select Joinus!',
                                customClass: {
                                    container: 'custom-swal-container', // Add a custom class to the container
                                },
                            })

                        }
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select Country Code!',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Mobile number is invalid!',
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email ID is invalid!',
            })
        }
    }

    EditData = () => {
        if (validator.isEmail(this.state.emailid)) {
            if (this.state.mobilenumber.length == 10) {
                if (this.state.countrycode != '') {
                    if (this.state.Password != "") {
                        if (this.state.Password == this.state.confirmpassword) {
                            // let obj = {
                            //     'query': `[dbo].[Seetech_Web_Proc_SignUpForm_Submit]  @FirstName='{0}',@LastName='{1}',@ContactNumber='{2}',@EmailAddress='{3}', @Username='{4}', @Password='{5}', @CountryCode='{6}',@CompanyName='{7}'`,
                            //     'queryArr': [`${this.state.firstname}`, `${this.state.lastname}`, `${this.state.mobilenumber}`, `${this.state.emailid}`, `${this.state.Username}`, `${this.state.Password}`, `${this.state.countrycode}`,`${this.state.companyname}`]
                            // }
                            // Hexapi(obj).then((res) => {
                            //     console.log(res);
                            //     Swal.fire({
                            //         position: 'center',
                            //         icon: 'success',
                            //         title: `${res[''][0].msg}`,
                            //         showConfirmButton: false,
                            //         timer: 1500

                            //     }).then(() => {
                            //         this.setState({OpenAddScreen:false})
                            //     })
                            // })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Your Password is not matching',
                            })
                        }
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select Country Code!',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Mobile number is invalid!',
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email ID is invalid!',
            })
        }
    }

    AddUserdata = () => {
        // console.log(this.state.Password, this.state.Username, this.state.companyname, this.state.countrycode,
        //     , this.state.emailid, this.state.mobilenumber, this.state.lastname)

        if (validator.isEmail(this.state.emailid)) {
            if (this.state.mobilenumber.length <= 15) {
                if (this.state.countrycode != '') {
                    // if (validator.isStrongPassword(password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })) {
                    if (this.state.Password != "") {
                        if (this.state.Password == this.state.confirmpassword) {
                            let obj = {
                                'query': `[dbo].[Seetech_Web_Proc_SignUpForm_Submit]  @FirstName='{0}',@LastName='{1}',@ContactNumber='{2}',@EmailAddress='{3}', @Username='{4}', @Password='{5}', @CountryCode='{6}',@CompanyName='{7}'`,
                                'queryArr': [`${this.state.firstname}`, `${this.state.lastname}`, `${this.state.mobilenumber}`, `${this.state.emailid}`, `${this.state.Username}`, `${this.state.Password}`, `${this.state.countrycode}`, `${this.state.companyname}`]
                            }
                            Hexapi(obj).then((res) => {
                                console.log(res);
                                Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: `${res[''][0].msg}`,
                                    showConfirmButton: false,
                                    timer: 1500

                                }).then(() => {
                                    this.setState({ OpenAddScreen: false })
                                })
                            })
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Your Password is not matching',
                            })
                        }
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select Country Code!',
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Mobile number is invalid!',
                })
            }
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Email ID is invalid!',
            })
        }
    }

    Addform = () => {
        this.setState({ Password: "", firstname: '', lastname: '', mobilenumber: '', emailid: '', Username: "", countrycode: '', companyname: "" }, () => {
            this.setState({ OpenAddScreen: true })
        })
    }


    render() {

        // console.log(this.props.pagination)
        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}
                <div style={{ height: 'calc(78vh - 5px)' }}>


                    <div style={{ position: 'relative', height: "inherit" }}>
                        {
                            this.props.isNavbar &&
                            <div className="_tablenavbar2">
                                <div className="_left">
                                    <span>{this.props.heading}</span>
                                    <span className='Add1' style={{ padding: '5px', fontSize: '0.9rem', background: 'rgb(208, 132, 12)', borderRadius: '10px', cursor: 'pointer' }} onClick={() => { this.Addform() }}>Add +</span>

                                </div>
                                <div className="_right">
                                    <Tooltip title="Refresh">
                                        <IconButton style={{ color: '#fff' }} onClick={() => { this.setState({ isDataFound: false }); this.props.callback(true) }} >
                                            <SyncIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <select onChange={(e) => { this.setState({ Columnname: e.target.value }); console.log(e.target.value) }}
                                        style={{ height: '30px', marginRight: '10px', borderRadius: '6px' }} value={this.state.Columnname} >
                                        <option value="">---Select----</option>
                                        {this.state.ColumnData.map((s, index) => {
                                            return (
                                                <option value={s} key={index}>
                                                    {s}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {/* <SyncIcon onClick={() => { this.props.callback(true); this.setState({ isDataFound: false }) }} /> */}

                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: "250px", padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...'
                                        onChange={(e) => this.setState({ searchValue: e.target.value })}
                                    />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            null
                                    }
                                    <Tooltip title="Open Search">
                                        <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }
                                            , () => {
                                                this.storeSearch(this.state.searchValue);
                                                // if (document.getElementById('searchBarFocus')) {
                                                //     document.getElementById('searchBarFocus').focus()
                                                // }
                                            }
                                        )}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <span className='Add2' style={{ padding: '5px', fontSize: '0.9rem', background: 'rgb(208, 132, 12)', borderRadius: '10px', cursor: 'pointer' }} onClick={() => { this.Addform() }}>Add +</span>
                                </div>
                            </div>
                        }
                        <Table id='_tableGrid' className={'table-hover'} responsive style={{ width: '100%' }} >
                            <thead style={this.props.headStyle} >
                                {/* style={this.props.headStyle}} */}
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {

                                this.state.isDataFound ?
                                    this.state.rowData.length > 0
                                        ?
                                        <tbody>
                                            {
                                                this.getRowsData(this.state.rowData)
                                            }
                                        </tbody>
                                        :
                                        <tbody>
                                            <div style={{ marginTop: '10%', marginLeft: '-15cm' }}>
                                                <h3>No Data Found!</h3>
                                            </div>
                                        </tbody>
                                    :
                                    <tbody>
                                        <div style={{ marginTop: '10%', marginLeft: '-15cm' }}>
                                            <h3>
                                                <Spinner animation='border' style={{ color: '#f9b101' }} />
                                            </h3>
                                        </div>
                                    </tbody>

                            }
                        </Table>
                        {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter">

                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        }

                    </div>
                </div>
                <Modal id="documentmodal" centered animation={true} show={this.state.membership} onHide={() => this.setState({ membership: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Approval Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row" style={{ width: '95%', margin: 'auto' }}>

                            <label ><b>Origin</b><span style={{ color: 'red' }}> *</span></label>
                            <select className="signupinput" onChange={(e) => { this.setState({ origin: e.target.value }); console.log(e.target.value) }}
                                style={{ marginTop: '5px', height: '50px' }} value={this.state.origin} >
                                <option value="">---Select----</option>
                                {this.state.origindata.map((s, index) => {
                                    return (
                                        <option value={s.originid}>
                                            {s.origin}
                                        </option>
                                    );
                                })}
                            </select>

                            <label><b>Join us</b><span style={{ color: 'red' }}> *</span></label>
                            <select name="receivedBy" className="signupinput" onChange={(e) => { this.setState({ joinus: e.target.value }); console.log(e.target.value) }}
                                style={{ marginTop: '5px', height: '50px' }} value={this.state.joinus} >
                                <option value="">---Select----</option>
                                {this.state.joindata.map((s, index) => {
                                    return (
                                        <option value={s.usertypeid}>
                                            {s.usertype}
                                        </option>
                                    );
                                })}
                            </select>

                            <label><b>Default Scheme</b><span style={{ color: 'red' }}> *</span></label>
                            <select name="receivedBy" className="signupinput" onChange={(e) => { this.setState({ schemeid: e.target.value }); console.log(e.target.value) }}
                                style={{ marginTop: '5px', height: '50px' }} value={this.state.schemeid} >
                                <option value="">---Select----</option>
                                {this.state.schemedata.map((s, index) => {
                                    return (
                                        <option value={s.schemeid}>
                                            {s.schemename} - oem-s :{s.oemservicecharge},p:{s.oempackingpercentage},d:{s.oemspecialdiscount}; Af-s:{s.aftermarketservicecharge} ,p:{s.aftermarketpackingcharge},d:{s.aftermarketspecialdiscount}
                                        </option>
                                    );
                                })}
                            </select>
                            <label><b>Select Currency</b><span style={{ color: 'red' }}> *</span></label>
                            <select name="receivedBy" className="signupinput" onChange={(e) => { this.setState({ currencyid: e.target.value }); console.log(e.target.value) }}
                                style={{ marginTop: '5px', height: '50px' }} value={this.state.currencyid} >
                                <option value="">---Select----</option>
                                {this.state.CurrencyData.map((s, index) => {
                                    return (
                                        <option value={s.currencyid}>
                                            {s.currency}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { this.Approvedrequest(this.state.rowuserid) }}>Submit</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                {/* Edit Modal */}
                <Modal id="documentmodal" fullscreen centered animation={true} show={this.state.Editform} onHide={() => this.setState({ Editform: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row" style={{ width: '95%', margin: 'auto' }}>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.firstname} onChange={(e) => { this.setState({ firstname: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Last Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.lastname} onChange={(e) => { this.setState({ lastname: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Mobile Number</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.mobilenumber} onChange={(e) => this.setphonenumber(e)} ></input>
                                </div>

                                <div style={{ width: '100%' }}>
                                    <label ><b>Email Id</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.emailid} onChange={(e) => { this.setState({ emailid: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Company Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.companyname} onChange={(e) => { this.setState({ companyname: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <div>
                                        <label ><b>Country Code</b><span style={{ color: 'red' }}> *</span></label>
                                    </div>
                                    <select className="signupinput" onChange={(e) => { this.setState({ countrycode: e.target.value }); console.log(e.target.value) }}
                                        style={{ height: '45px' }} value={this.state.countrycode} >
                                        <option value="">---Select----</option>
                                        {Countrydata.map((s, index) => {
                                            return (
                                                <option value={s.dial_code}>
                                                    {s.name}:{s.dial_code}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {/* <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.countrycode} onChange={(e) => { this.setState({ countrycode: e.target.value }) }}></input> */}
                                </div>
                            </div>

                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Username</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.Username} onChange={(e) => { this.setState({ Username: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Password</b><span style={{ color: 'red' }}> *</span></label>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <input className="signupinput" type={this.state.showPassword ? "text" : "password"} style={{ marginTop: '5px', height: '40px' }} value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }) }}></input>
                                        <span style={{ position: 'absolute', right: '3%', marginTop: '10px' }} onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }}>
                                            {
                                                this.state.showPassword ?
                                                    <i class="fa fa-eye"></i>
                                                    :
                                                    <i class="fa fa-eye-slash"></i>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='Fieldstyle' style={{ justifyContent: 'flex-start' }}>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Origin</b><span style={{ color: 'red' }}> *</span></label>
                                    <select className="signupinput" onChange={(e) => { this.setState({ origin2: e.target.value }); console.log(e.target.value) }}
                                        style={{ marginTop: '5px', height: '50px' }} value={this.state.origin2} >
                                        <option value="">---Select----</option>
                                        {this.state.origindata.map((s, index) => {
                                            return (
                                                <option value={s.originid}>
                                                    {s.origin}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>



                                <div style={{ width: '100%' }}>
                                    <label><b>Join us</b><span style={{ color: 'red' }}> *</span></label>
                                    <select name="receivedBy" className="signupinput" onChange={(e) => { this.setState({ joinus2: e.target.value }); console.log(e.target.value) }}
                                        style={{ marginTop: '5px', height: '50px' }} value={this.state.joinus2} >
                                        <option value="">---Select----</option>
                                        {this.state.joindata.map((s, index) => {
                                            return (
                                                <option value={s.usertypeid}>
                                                    {s.usertype}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>


                            <div className='Fieldstyle' style={{ justifyContent: 'flex-start' }}>
                                <div style={{ width: '100%' }}>
                                    <label><b>Select Scheme</b><span style={{ color: 'red' }}> *</span></label>
                                    <select name="receivedBy" className="signupinput" onChange={(e) => { this.setState({ schemeid2: e.target.value }); console.log(e.target.value) }}
                                        style={{ marginTop: '5px', height: '50px' }} value={this.state.schemeid2} >
                                        <option value="">---Select----</option>
                                        {this.state.schemedata.map((s, index) => {
                                            return (
                                                <option value={s.schemeid}>
                                                    {s.schemename} - oem-s :{s.oemservicecharge},p:{s.oempackingpercentage},d:{s.oemspecialdiscount}; Af-s:{s.aftermarketservicecharge} ,p:{s.aftermarketpackingcharge},d:{s.aftermarketspecialdiscount}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div style={{ width: '100%' }}>

                                    <label><b>Select Currency</b><span style={{ color: 'red' }}> *</span></label>
                                    <select name="receivedBy" className="signupinput" onChange={(e) => { this.setState({ currencyid2: e.target.value }); console.log(e.target.value) }}
                                        style={{ marginTop: '5px', height: '50px' }} value={this.state.currencyid2} >
                                        <option value="">---Select----</option>
                                        {this.state.CurrencyData.map((s, index) => {
                                            return (
                                                <option value={s.currencyid}>
                                                    {s.currency}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { this.SubmitEditDetails(this.state.rowuserid) }}>Submit</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal id="documentmodal" fullscreen centered animation={true} show={this.state.OpenAddScreen} onHide={() => this.setState({ OpenAddScreen: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{"Add New User"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row" style={{ width: '95%', margin: 'auto' }}>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.firstname} onChange={(e) => { this.setState({ firstname: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Last Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.lastname} onChange={(e) => { this.setState({ lastname: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Mobile Number</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.mobilenumber} onChange={(e) => this.setphonenumber(e)} ></input>
                                </div>

                                <div style={{ width: '100%' }}>
                                    <label ><b>Email Id</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.emailid} onChange={(e) => { this.setState({ emailid: e.target.value }) }}></input>
                                </div>
                            </div>
                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Company Name</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.companyname} onChange={(e) => { this.setState({ companyname: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <div>
                                        <label ><b>Country Code</b><span style={{ color: 'red' }}> *</span></label>
                                    </div>
                                    <select className="signupinput" onChange={(e) => { this.setState({ countrycode: e.target.value }); console.log(e.target.value) }}
                                        style={{ height: '45px' }} value={this.state.countrycode} >
                                        <option value="">---Select----</option>
                                        {Countrydata.map((s, index) => {
                                            return (
                                                <option value={s.dial_code}>
                                                    {s.name}:{s.dial_code}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {/* <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.countrycode} onChange={(e) => { this.setState({ countrycode: e.target.value }) }}></input> */}
                                </div>
                            </div>

                            <div className='Fieldstyle' >
                                <div style={{ width: '100%' }}>
                                    <label ><b>Username</b><span style={{ color: 'red' }}> *</span></label>
                                    <input className="signupinput" style={{ marginTop: '5px', height: '40px' }} value={this.state.Username} onChange={(e) => { this.setState({ Username: e.target.value }) }}></input>
                                </div>
                                <div style={{ width: '100%' }}>
                                    <label ><b>Password</b><span style={{ color: 'red' }}> *</span></label>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <input className="signupinput" type={this.state.showPassword ? "text" : "password"} style={{ marginTop: '5px', height: '40px' }} value={this.state.Password} onChange={(e) => { this.setState({ Password: e.target.value }) }}></input>
                                        <span style={{ position: 'absolute', right: '3%', marginTop: '10px' }} onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }}>
                                            {
                                                this.state.showPassword ?
                                                    <i class="fa fa-eye"></i>
                                                    :
                                                    <i class="fa fa-eye-slash"></i>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='Fieldstyle' style={{ justifyContent: 'flex-start' }}>
                                <div style={this.state.windowwidth < 600 ? { width: '100%' } : { width: '49%' }}>
                                    <label ><b>Confirm Password</b><span style={{ color: 'red' }}> *</span></label>
                                    <div style={{ display: 'flex', position: 'relative' }}>
                                        <input className="signupinput" type={this.state.showPassword ? "text" : "password"} style={{ marginTop: '5px', height: '40px' }} value={this.state.confirmpassword} onChange={(e) => { this.setState({ confirmpassword: e.target.value }) }}></input>
                                        <span style={{ position: 'absolute', right: '3%', marginTop: '10px' }} onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }}>
                                            {
                                                this.state.showPassword ?
                                                    <i class="fa fa-eye"></i>
                                                    :
                                                    <i class="fa fa-eye-slash"></i>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" id="Submitbtn" style={{ marginTop: '20px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <button style={{ color: 'white', margin: '8px 0px', padding: '10px 30px', backgroundColor: 'black', borderRadius: '10px' }} onClick={() => { this.AddUserdata() }}>Submit</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </>

        )
    }
}
