import React, { useEffect, useState } from 'react'
import './blog.scss'
import { Link } from 'react-router-dom'
import { FaAnglesRight } from "react-icons/fa6";
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Modal, Spinner } from 'react-bootstrap';


const Blog = () => {
    document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
    const [blogdata, setblogdata] = useState([]);
    const [showLoading, setshowLoading] = useState(true);
    const fetchdata = async () => {
      const obj = {
        query: `[dbo].[Seetech_Web_Proc_Blog_GetList]`,
        queryArr: [],
      };
      try {
        const dataget = await Hexapi(obj);
        setshowLoading(false)
        const setdata = dataget[""];
        console.log(setdata, "setdata");
        setblogdata(setdata);
      } catch (error) {
        console.log("error");
      }
    };
    useEffect(() => {
      fetchdata();
    }, []);
    return (
        showLoading ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
              <h3>
                <Spinner animation='border' style={{ color: '#f9b101' }} />
              </h3>
            </div>:
        <>
            
            <div className="blog-root">
                <div className="container">{
                        blogdata?.map((i) => (
                            <div className=' blog-card col-lg-3 col-md-4 col-sm-12' id={i.recid}>
                            <Link to={`/blog/${i.recid}/${i.title}`}>
                                <div className=" blog-img-div">
                                  <img src={`${sessionStorage.getItem('Apipathurl')}${i.imagepath}`}/>
                                </div>
                            </Link>
                                <div className="blog-body">
                                   <h1>{i.title.slice(0, 50)}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: i.body.slice(0,300) }} ></div>
                                    <Link to={`/blog/${i.recid}`}> <p style={{ color: '#f9b101', fontWeight: '700' }}>read more <FaAnglesRight /> </p>  </Link>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default Blog