import React, { useState, useEffect, useRef } from 'react'
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import { Carousel } from 'react-responsive-carousel';
import SearchIcon from '@mui/icons-material/Search';
import { Modal, Spinner } from 'react-bootstrap';
import Products from './Products';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

const Wishlist = (props) => {
    const dispatch = useDispatch();
    const [productsdata, setproducts] = useState([]);
    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [productdata, setproductdata] = useState([]);
    const [imagedata, setimagedata] = useState([]);
    const [openproductmodal, setopenproductmodal] = useState(false);
    const [openproductjs, setopenproductjs] = useState(false)
    const [showwishlist, setshowwishlist] = useState(true);
    const [showLoading, setshowLoading] = useState(true);
    const [OverseasINR, setOverseasINR] = useState(false);
    const [Userorigin, setUserorigin] = useState('');
    const [usertype, setusertype] = useState('');
    const [searchvalue, setsearchvalue] = useState('');
    const [ProductPrice, setProductPrice] = useState('');
    const [CartPrice, setCartPrice] = useState([]);
    const CartItems = useSelector(state => state.counter.CartItems);


    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        dispatch({ type: 'SaveRoutes', payload: { route: "wishlist" } });

        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`]

        }
        Hexapi(obj1).then((res) => {
            // console.warn(res);
            if (res[""][0]) {
                setUserorigin(res[""][0]?.origin);
                setusertype(res[""][0]?.usertype)
            } else {
                setusertype("")
                setUserorigin("");
            }
            // setshowLoading(false)
        });
        // setUserorigin(props.UOrigin)
        // setusertype(props.Utype)

        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }

    }, [])


    useEffect(() => {
        setshowLoading(true)
        console.log('wish data', productsdata)

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        let obj = "";
        if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {
            obj = {
                'query': `[dbo].[Seetech_Web_Proc_Wishlist_GetData] @UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj).then((res) => {
                console.log(res)
                if (res[""].length > 0) {
                    let data = res[""];
                    console.log(data);
                    setproducts(res[""]);

                    let QtyRecID = [];
                    res[""].map((s, index) => {
                        if (s.qty != null) {
                            QtyRecID.push(s.recid)
                        }
                    })
                    setCartPrice(QtyRecID);
                    setshowwishlist(true);
                    setshowLoading(false)

                } else {
                    setshowLoading(false)
                    setshowwishlist(false)
                }
            })


            // if (sessionStorage.getItem('origin')?.toLowerCase() == "overseas") {
                let obj1 = {
                    'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
                    'queryArr': [`${sessionStorage.getItem('userid')}`]
                }
                Hexapi(obj1).then((res) => {
                    console.log(res)
                    console.warn(res);
                if(res[""][0].origin.toLowerCase() == "overseas"){
                    if (res[""][0].currency == "inr" || res[""][0].currency == "INR") {
                        setOverseasINR(true);
                    } else {
                        setOverseasINR(false);
                    }
                }
                });
            }
        // } else {
        //     window.location.href = "/"
        // }
    }, [])


    const openProductscreen = (data) => {
        let price = null;

        if (Userorigin.toLowerCase() === "domestic") {
            if (usertype.toLowerCase() === "visitor") {
                // Do nothing, or you can set a default value
            } else if (usertype.toLowerCase() === "retailer") {
                price = `₹${Number(data.mrp).toFixed(2)}`;
            } else {
                price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
            }
        } else {
            if (OverseasINR === true) {
                price = `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
            } else {
                price = `$${Number(data.overseasprice).toFixed(2)}`;
            }
        }
        setProductPrice(price);
        setproductdata(data);

        // let images = data.images;
        // images = images.split(',');

        let images = data?.images;
        images = images.split(',');
        images = images.filter(item => !item.includes('default'))
        setimagedata(images);

        setTimeout(() => {
            setopenproductmodal(true)
        }, 300);
    }

    const deleteProduct = (id) => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Wishlist_Remove]@UserID='{0}',  @ProductID='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `${id}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            let obj1 = {
                'query': `[dbo].[Seetech_Web_Proc_Wishlist_GetData] @UserID='{0}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`]
            }
            Hexapi(obj1).then((res) => {
                console.log(res)
                if (res[""].length > 0) {
                    let data = res[""];
                    console.log(data);
                    setproducts(res[""])
                    setshowwishlist(true);
                    setshowLoading(false)

                } else {
                    setshowLoading(false)
                    setshowwishlist(false)
                }
            })
        });
    }

    const clearwishlist = () => {
        // setshowLoading(true);
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Wishlist_Remove]@UserID='{0}',  @ProductID='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `-1`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            setshowLoading(false);
            setproducts(res);
            setshowwishlist(false);
        });
    }

    const notify = () =>
        toast.success('Item Added to Cart !', {
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        });

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace' && searchvalue.length === 1) {
            clearSearch();
        }
    };

    const AddtoCart = (Id, Qty, mrp) => {
        // const qty=
        const productprice = mrp.slice(1);

        if (CartPrice.some((it) => it == Id) === false) {
            dispatch({ type: 'CartItems', payload: { CartItems: CartItems + 1 } });
            let Cartid = [...CartPrice];
            Cartid.push(Id)
            setCartPrice(Cartid)
        }

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Cart_Add] @UserID='{0}',@ProductID='{1}',@Qty='{2}',@Price='{3}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `${Id}`, `${Qty}`, `${productprice}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            // setCartQty("");
            notify()
        });
    }

    const StoreSearch = () => {
        if (searchvalue !== "") {
            let obj = {
                'query': `[dbo].[Seetech_Web_Proc_Wishlist_searchdata] @UserID='{0}', @SearchValue='{1}'`,
                'queryArr': [`${sessionStorage.getItem('userid')}`, `${searchvalue}`]
            }
            Hexapi(obj).then((res) => {
                console.log(res);
                if (res[""].length > 0) {
                    setproducts(res[""])
                    setshowwishlist(true)
                    setshowLoading(false)
                } else {
                    setproducts(res[""])
                    setshowwishlist(false)
                    setshowLoading(false)
                }

            });
        }
        else {
            setshowwishlist(true)
            setshowLoading(false)
            toast.warning('Please put some value for search!', {
                position: toast.POSITION.TOP_CENTER, autoClose: 500
            });
        }
    }

    const clearSearch = () => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Wishlist_searchdata] @UserID='{0}', @SearchValue='{1}'`,
            'queryArr': [`${sessionStorage.getItem('userid')}`, `-1`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            setproducts(res[""])
            setsearchvalue("");
            setshowwishlist(true)
            setshowLoading(false)
        });
    }

    return (
        <>
            {
                // openproductjs ?
                //     <Products callback={() => { callback() }} materialdata={productdata} imagedata={imagedata} />
                //     :
                <div style={{ width: '90%', height: 'auto', margin: 'auto', }}>

                    <div style={{ width: '100%', margin: '0 0 50px 0' }}>
                        <>

                            <div style={{ display: 'flex', borderBottom: '2px solid #f9b101', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                                <h3 style={{ margin: '0' }}>Wishlist</h3>
                            </div>
                            {/* <div style={{ display: 'flex', borderTop: '2px solid #f9b101', borderBottom: '2px solid #f9b101', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                                    <input type='text' placeholder='Search Products...'
                                        value={searchvalue}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => { setsearchvalue(e.target.value) }}
                                        style={{ border: 'none', borderBottom: '2px solid black', outline: 'none', color: 'black', fontSize: '0.9rem' }}></input>

                                    <span onClick={() => { StoreSearch() }}>
                                        <SearchIcon style={{ color: 'black', cursor: 'pointer' }} />
                                    </span>

                                    {
                                        searchvalue.length > 0 ?
                                            <CloseIcon onClick={() => { clearSearch(); setshowLoading(true) }} />
                                            :
                                            null
                                    }
                                </div>
                            </div> */}
                        </>
                        {
                            sessionStorage.getItem('userid') == undefined || sessionStorage.getItem('userid') == null || sessionStorage.getItem('userid') == "" ?
                                <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Link to="/login" style={{ textDecoration: 'none' }} className='LoginItemstyle'><h4 style={{ textAlign: 'center', color: 'white', textTransform: 'none !important' }}>Please Login </h4></Link>
                                </div>

                                :
                                showLoading ?
                                    <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)' }}>
                                        <h3>
                                            <Spinner animation='border' style={{ color: '#f9b101' }} />
                                        </h3>
                                    </div>
                                    :
                                    showwishlist === false ?
                                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <h4 style={{ textAlign: 'center', color: 'black' }}>No Wishlist</h4>
                                        </div>
                                        :
                                        <>
                                            {/* <div style={{ display: 'flex', borderTop: '2px solid #f9b101', borderBottom: '2px solid #f9b101', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                                                    <input type='text' placeholder='Search Products...'
                                                        value={searchvalue}
                                                        onKeyDown={handleKeyDown}
                                                        onChange={(e) => { setsearchvalue(e.target.value) }}
                                                        style={{ border: 'none', borderBottom: '2px solid black', outline: 'none', color: 'black', fontSize: '0.9rem' }}></input>

                                                    <span onClick={() => { StoreSearch() }}>
                                                        <SearchIcon style={{ color: 'black', cursor: 'pointer' }} />
                                                    </span>

                                                    {
                                                        searchvalue.length > 0 ?
                                                            <CloseIcon onClick={() => { clearSearch(); setshowLoading(true) }} />
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div> */}
                                            <div style={{ display: 'flex', justifyContent: ' flex-end', marginTop: '10px' }}>
                                                <span className='apply_filterstyle' style={{ backgroundColor: '#f9b101', cursor: 'pointer' }} onClick={() => { clearwishlist() }}>Clear Wishlist</span>
                                            </div>

                                            <div style={{ margin: '30px 0 30px ' }}>
                                                <div style={{ display: 'flex', flexFlow: 'row wrap', width: '100%' }}>
                                                    {
                                                        productsdata.map((data, index) => {
                                                            if (usertype != undefined && usertype != null && usertype != "") {

                                                                let images = data.images;
                                                                images = images.split(',');
                                                                // console.log(images);
                                                                let price = null;

                                                                if (Userorigin.toLowerCase() === "domestic") {
                                                                    if (usertype.toLowerCase() === "visitor") {
                                                                        // Do nothing, or you can set a default value
                                                                    } else if (usertype.toLowerCase() === "retailer") {
                                                                        price = `₹${Number(data.mrp).toFixed(2)}`;
                                                                    } else {
                                                                        price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
                                                                    }
                                                                } else {
                                                                    if (OverseasINR === true) {
                                                                        price = `₹ ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
                                                                    } else {
                                                                        price = `$${Number(data.overseasprice).toFixed(2)}`;
                                                                    }
                                                                }

                                                                return (
                                                                    <>
                                                                        <div className='shopcards'
                                                                            onClick={() => { openProductscreen(data); }}
                                                                        >

                                                                            <Carousel
                                                                                rewindWithAnimation={true} showArrows={false} infiniteLoop={true} showStatus={false} showIndicators={true} transitionTime={1000} showThumbs={false}>
                                                                                {
                                                                                    data.images != "" ?
                                                                                        // images.map((image, i) => {
                                                                                        //     return (
                                                                                        <div className='firstcarousel' style={{ width: '100%', height: '100%' }} >
                                                                                            <img src={'https://seetechparts.com/' + images[0].split('wp-content/')[1]} alt="Product image" ></img>
                                                                                        </div>
                                                                                        // )
                                                                                        // })
                                                                                        :
                                                                                        <div className='firstcarousel' style={{ width: '100%', height: '100%' }} >
                                                                                            <img src='https://seetechparts.com/uploads/2023/11/dummy.png' alt={"image"} ></img>
                                                                                        </div>


                                                                                }
                                                                            </Carousel>
                                                                            {/* <img src="https://boodmo.com/media/cache/catalog_image/images/categories/ddbeb81.jpg" alt="" style={{ height: '80px', margin: '0 auto 15px' }}></img> */}
                                                                            <span style={windowidth < 450 ? { fontWeight: '700', fontSize: '0.8rem' } : { fontWeight: '500' }}>{data.name}</span>
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5px' }}>
                                                                                <span style={windowidth < 450 ? { fontSize: '0.8rem', fontWeight: '400' } : { fontWeight: 'bold', margin: '0 10px' }}>
                                                                                    {
                                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                                            null
                                                                                            :
                                                                                            price
                                                                                    }
                                                                                </span>
                                                                                <span style={windowidth < 450 ? { fontSize: '0.8rem' } : { textDecoration: 'line-through' }}>
                                                                                    {
                                                                                        usertype == undefined || usertype == null || usertype == "" ?
                                                                                            null
                                                                                            :
                                                                                            Userorigin.toLowerCase() === "domestic" ? (
                                                                                                usertype.toLowerCase() == "wholesaler" &&
                                                                                                (
                                                                                                    `₹${Number(data.mrp).toFixed(2)}`
                                                                                                )
                                                                                            ) : null
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px', gap: '15px' }}>
                                                                                {
                                                                                    usertype == undefined || usertype == null || usertype == "" ?
                                                                                        <span style={{ backgroundColor: '#f9b101', padding: '5px 15px', fontSize: '1rem', borderRadius: '30px', minHeight: '30px', width: '80%', textAlign: 'center', color: 'white' }}>Login for details</span>
                                                                                        :
                                                                                        <>
                                                                                            {/* <input style={{ width: windowidth < 433 ? '30px' : '50px', borderRadius: '10px', fontSize: windowidth < 433 ? '0.8rem' : '0.9rem', height: windowidth < 380 ? '25px' : '27px', paddingLeft: windowidth < 380 ? '2px' : '5px' }} type='number'
                                                                                                value={CartQty}
                                                                                                onChange={(e) => { setCartQty(e.target.value); }}
                                                                                                placeholder='Qty..' /> */}
                                                                                            {

                                                                                                usertype?.toLowerCase() == "visitor" ?
                                                                                                    null
                                                                                                    :
                                                                                                    <span style={{ backgroundColor: '#f9b101', padding: '5px 15px', fontSize: windowidth < 405 ? '0.8rem' : '1rem', borderRadius: '30px', minHeight: '30px', width: '80%', textAlign: 'center', color: 'white', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, .3)' }}
                                                                                                        onClick={(e) => { e.stopPropagation(); AddtoCart(data.recid, data.moq, price) }}>
                                                                                                        Add to cart
                                                                                                    </span>
                                                                                            }
                                                                                            <span onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                deleteProduct(data.recid);
                                                                                                setshowLoading(true);
                                                                                            }}><DeleteIcon /></span>

                                                                                        </>

                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </>
                        }
                    </div>
                </div>

            }


            {
                productdata.length > 0 ? <Modal id="documentmodal" size={windowidth < 1000 ? "xl" : "lg"} centered animation={true} show={openproductmodal} onHide={() => setopenproductmodal(false)} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={windowidth < 530 ? { width: '100%', margin: 'auto', display: 'flex', flexFlow: 'wrap' } : { width: '100%', margin: 'auto', display: 'flex', flexFlow: 'nowrap' }}>
                            <div style={windowidth < 800 ? { width: '100%', height: 'auto', paddingRight: '18px' } : { width: '50%', height: 'auto', paddingRight: '10px' }}>
                                <div className='tile' style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                                    {/* <img src="https://seetechparts.com/wp-content/uploads/2022/03/JCB.webp" alt="" style={{ width: '320px', height: '280px', borderRadius: '10px' }}></img> */}

                                    <Carousel
                                        autoPlay={true} showIndicators={true} infiniteLoop={true} showStatus={false} showArrows={true} transitionTime={1000} showThumbs={false}>
                                        {
                                            imagedata.map((image, index) => {
                                                return (
                                                    <div className='firstcarousel' style={{ width: '100%', height: '100%' }} key={index}>
                                                        <img alt={index} src={image != "" ? 'https://seetechparts.com/' + image.split('wp-content/')[1] : 'https://seetechparts.com/uploads/2023/11/dummy.png'} style={windowidth > 1050 ? { width: '300px ', height: '300px', margin: 'auto' } : {}}></img>

                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>

                                </div>
                            </div>

                            {/* Rightside */}
                            <div style={windowidth < 800 ? { width: '100%', height: 'auto', padding: '20px 0' } : { width: '50%', height: 'auto', padding: '20px 0' }}>
                                <div style={{ display: 'flex', }}>
                                    <h5 style={{ marginBottom: '0' }}>{productdata.name}</h5>
                                    {/* <div><FavoriteBorderIcon /></div> */}
                                </div>
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '15px', fontWeight: '600', fontSize: '1.3rem' }}>
                                    {
                                        usertype == undefined || usertype == null || usertype == "" ?
                                            null
                                            :
                                            ProductPrice
                                    }
                                    <span style={{ textDecoration: 'line-through', fontSize: '16px', }}>
                                        {
                                            usertype == undefined || usertype == null || usertype == "" ?
                                                null
                                                :
                                                Userorigin.toLowerCase() === "Domestic" ? (
                                                    usertype.toLowerCase() == "Wholesaler" &&
                                                    (
                                                        `₹${Number(productdata.mrp).toFixed(2)}`
                                                    )
                                                ) : null
                                        }

                                    </span>
                                </div>
                                {/* <div style={{ borderTop: '1px solid #f9b101', display: 'flex', gap: '10px', alignItems: 'center', paddingTop: '15px', justifyContent: 'space-between' }}>
                    <div>
                        <span style={{ color: '#f9b101', fontSize: '17px' }}>Category: </span><span><b>{productdata.category}</b></span>
                    </div>
                    <div>
                        <span style={{ color: '#f9b101', fontSize: '17px' }}>Tags: </span><span><b>{productdata.suitableformodel}</b></span>
                    </div>
                </div> */}
                                <div >
                                    <h6 style={{ fontSize: '16px' }}>Description</h6>
                                    <div>
                                        <span style={{ color: '#817575', fontSize: '0.9rem' }}>{productdata.description}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30px', gap: '10px' }}>
                                    {/* <input style={{ width: windowidth < 433 ? '30px' : '50px', borderRadius: '10px', fontSize: windowidth < 433 ? '0.8rem' : '0.9rem', height: windowidth < 380 ? '25px' : '27px', paddingLeft: windowidth < 380 ? '2px' : '5px' }} type='number'
                        value={CartQty}
                        onChange={(e) => { setCartQty(e.target.value); }}
                        placeholder='Qty..' /> */}
                                    {/* <span style={{ backgroundColor: '#f9b101', padding: '5px 15px', fontSize: '1rem', borderRadius: '30px', minHeight: '30px', width: '60%', textAlign: 'center', color: 'white', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); AddtoCart(productdata.recid, CartQty*productdata.moq) }}>Add to cart</span> */}

                                    {

                                        usertype?.toLowerCase() == "visitor" ?
                                            null
                                            :
                                            <span style={{ backgroundColor: '#f9b101', padding: '5px 15px', fontSize: windowidth < 405 ? '0.8rem' : '1rem', borderRadius: '30px', minHeight: '30px', width: '80%', textAlign: 'center', color: 'white', cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); AddtoCart(productdata.recid, productdata.moq, ProductPrice) }}>Add to cart</span>
                                    }
                                </div>
                                <Link to={`/Product/${productdata.recid}/${productdata.suitableforpartreference.replaceAll('/', '-')}`} style={{ textDecoration: 'none', color: 'black' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                                        {
                                            <span style={{ backgroundColor: 'rgb(47 46 46)', padding: '5px 15px', fontSize: '1rem', borderRadius: '10px', minHeight: '30px', width: '80%', textAlign: 'center', color: 'white', cursor: 'pointer' }} onClick={() => { setopenproductmodal(false); setopenproductjs(true) }}>See product details</span>
                                        }
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal > : null
            }



            <ToastContainer
            // style={windowidth < 450 ? { width: '200px', fontSize: '0.7rem', } : null}
            />

        </>
    )
}

export default Wishlist