import React, { useEffect, useState } from 'react'
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom'
import caution from "../Comp/Seetechimages/Seetechimages/caution.webp"
import seetechimg from "../Comp/Seetechimages/Seetechimages/seetech.webp"


const Footer = () => {

    const [windowidth, setwindowwidth] = useState(window.innerWidth);
    const [ShowUserLink, setShowUserLink] = useState(false);

    useEffect(() => {

        if (sessionStorage.getItem('userid') != undefined && sessionStorage.getItem('userid') != null && sessionStorage.getItem('userid') != "") {
            setShowUserLink(true);
        } else {
            setShowUserLink(false);
        }

        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }

    }, [])


    return (
        <>
            {/* Footer */}
            <div style={{ width: '100%', height: '100%', margin: 'auto', backgroundColor: 'black', padding: '1rem 0px 0px 0px' }}>
                {/* <h1 style={{color:'white',textAlign:'center',fontSize:'large'}}>Welcome to Seetech Parts - Your one stop Sourcing partner for Heavy Machinery Spare Parts</h1> */}

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0px", color: '#f9b101', flexDirection: 'column' }} >
                    <div style={{ maxWidth: '2rem' }}>
                        <img src={caution} alt="" width='100%' />
                    </div>

                    <h6 style={{ marginTop: '5px' }}>DISCLAIMER</h6>
                </div>

                <marquee style={{ color: '#fff', width: '100%' }} >
                    <h6 style={{ margin: '0' }}>   Part no. and Name are used for reference / suitability only thus may not necessarily imply product by Original equipment manufacturer </h6>
                </marquee>


                <div style={windowidth < 700 ? { width: '90%', margin: 'auto' } : { width: '65%', margin: 'auto' }}>
                    <div
                        style={windowidth < 500 ? {} : { height: 'auto', margin: '20px 0 0px 0', display: 'flex', justifyContent: 'space-around' }}
                    >
                        {
                            // windowidth > 800 ?
                            <div className='footer1' style={windowidth < 500 ? { marginTop: '10px' } : {}}>
                                <h6 style={{ color: '#f9b101' }}>Site Link</h6>
                                <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
                                    <Link to={"/"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='footeritem'><a href="">Home</a></li>
                                    </Link>

                                    <Link to={"/about"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='footeritem'><a href="">About us</a></li>
                                    </Link>

                                    <Link to={"/Shop"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='footeritem'><a href="">Shop</a></li>
                                    </Link>
                                    {
                                        ShowUserLink == true ?
                                            <Link to={"/wishlist"} style={{ textDecoration: 'none', color: 'white' }}>
                                                <li className='footeritem'><a href=""> Wishlist </a></li>
                                            </Link>
                                            :
                                            null
                                    }
                                    <Link to={"/contact"} style={{ textDecoration: 'none', color: 'white' }}>
                                        <li className='footeritem'><a href="">Contact us</a></li>
                                    </Link>

                                    {/* <li className='footeritem'><a href=""> Request a Quote </a></li> */}
                                </ul>
                            </div>
                            // :
                            // null
                        }
                        <div className='footer2' style={windowidth < 500 ? { marginTop: '20px' } : {}}>
                            <h6 style={{ color: '#f9b101' }}>Terms & Conditions</h6>
                            <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
                                <Link to={"/termsandconditions"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='footeritem'><a href="">Terms & Conditions </a></li>
                                </Link>

                                <Link to={"/privacypolicy"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='footeritem'><a href="">Privacy Policy</a></li>
                                </Link>

                                <Link to={"/disclaimerpolicy"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='footeritem'><a href="">Disclaimer Policy</a></li>
                                </Link>

                                <Link to={"/warranty"} style={{ textDecoration: 'none', color: 'white' }}>
                                    <li className='footeritem'><a href="">Warranty Policy</a></li>
                                </Link>
                                {/* <li className='footeritem'><a href="">Bulk Order Form</a></li> */}
                                {/* <li className='footeritem'><a href=""> Investor Relations </a></li> */}
                            </ul>
                        </div>
                        {/* <div className='footer3'>
                                <h6 style={{ color: '#f9b101' }}>Policy</h6>
                                <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
                                    <li className='footeritem'><a href="">About us</a></li>
                                    <li className='footeritem'><a href="">Contact us</a></li>
                                    <li className='footeritem'><a href="">FAQ</a></li>
                                    <li className='footeritem'><a href=""> Careers </a></li>
                                    <li className='footeritem'><a href=""> Investor Relations </a></li>
                                </ul>
                            </div> */}
                        {/* {
                                windowidth > 800 ?
                                    <div className='footer4'>
                                        <h3 style={{ color: '#f9b101' }}>Useful Links</h3>
                                        <ul style={{ margin: '0', padding: '0', listStyle: 'none' }}>
                                            <li className='footeritem'><a href="">About us</a></li>
                                            <li className='footeritem'><a href="">Contact us</a></li>
                                            <li className='footeritem'><a href="">FAQ</a></li>
                                            <li className='footeritem'><a href=""> Careers </a></li>
                                            <li className='footeritem'><a href=""> Investor Relations </a></li>
                                        </ul>
                                    </div>
                                    : null
                            } */}
                    </div>
                </div>
            </div>

            <div style={{ width: '100%', height: '100%', margin: 'auto', backgroundColor: 'black', padding: '30px 0px 20px 0px' }}>
                <div className='Footerstyle'>
                    <div>
                        <img src={seetechimg} alt="" style={{ height: '50px', width: '120px' }}></img>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
                            <a href='https://www.facebook.com/seetechparts' target='_blank' ><span className='' style={{ cursor: 'pointer' }}><FacebookIcon style={{ color: '#f9b101' }} /></span></a>
                            <a href='https://www.instagram.com/seetechparts' target='_blank' ><span className='' style={{ cursor: 'pointer' }}><InstagramIcon style={{ color: '#f9b101' }} /></span></a>
                            <a href='http://wa.me/918178967019' target='_blank' ><span className='' style={{ cursor: 'pointer' }}><WhatsAppIcon style={{ color: '#f9b101' }} /></span></a>
                        </div>

                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontFamily: 'CodecProWarm,TVHPro,Roboto,Arial,sans-serif', textTransform: 'uppercase', color: '#f9b101', fontSize: '1.1rem' }}>Lets Start Today!</span>
                    </div>
                </div>
            </div>

            <div style={{ width: '100%', height: '100%', margin: 'auto', backgroundColor: 'black' }}>
                <div style={{ width: '92%', margin: 'auto', textAlign: 'center' }}>

                    <span style={{ color: "#f9b101", fontSize: '0.8rem' }}>Copyright ©SEETECH EARTHMOVING EQUIPMENTS PRIVATE LIMITED. All rights reserved</span>

                </div>
            </div>
        </>
    )
}

export default Footer