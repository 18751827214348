import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { TroubleshootSharp } from '@mui/icons-material';
// import { CCarousel, CCarouselItem, CImage } from '@coreui/react';
import Hexapi from '../usefulcomps/HexAPI/Hexapi';
import Swal from 'sweetalert2';
import { Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const Products = (props) => {
  const dispatch = useDispatch();
  const [windowidth, setwindowwidth] = useState(window.innerWidth);
  const [images, setimages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [mainimage, setmainimage] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [zoomimage, setzoomimage] = useState(null);
  const [favouriteItem, setfavouriteItem] = useState(true);
  const [CartItems, setCartItems] = useState(false);
  const [ProductPrice, setProductPrice] = useState("");
  const [Quantity, setQuantity] = useState(1);
  const [QuantityMoq, setQuantityMoq] = useState(1);
  // const [Quantitydata, setQuantitydata] = useState(1);
  const [showCartModal, setshowCartModal] = useState(false);
  const [showquotationpdf, setshowquotationpdf] = useState(false);
  const [quotationpdffile, setquotationpdffile] = useState('');
  const [OverseasINR, setOverseasINR] = useState(false);
  const [QuantityCart, setQuantityCart] = useState('1');
  const [Userorigin, setUserorigin] = useState('');
  const [usertype, setusertype] = useState('');
  const [productdata, setproductdata] = useState([]);
  const [imagedata, setimagedata] = useState([]);
  const [showLoading, setshowLoading] = useState(true);
  const { ProductId, RecID, FilterValue } = useParams();
  const [isItemAdded, setisItemAdded] = useState(false);
  const [userid, setuserid] = useState(6);
  const TotalCartItems = useSelector(state => state.counter.CartItems);

  useEffect(() => {
    // console.log(sessionStorage.getItem('userid'))
    if (sessionStorage.getItem('userid') == null) {
      setuserid(6)
    } else {
      setuserid(sessionStorage.getItem('userid'))
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    // console.log(ProductId, RecID, FilterValue);
    dispatch({ type: 'SaveRoutes', payload: { route: "Product" } });
    // console.log(atob(Filter))
    // if (sessionStorage.getItem('userid') == undefined || sessionStorage.getItem('userid') == null || sessionStorage.getItem('userid') == "") {
    //   window.location.href = `/shop/${RecID}`
    // }
    let Origin = "";
    let UserType = "";
    let PriceValue = '';
    let obj1 = {
      'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
      'queryArr': [`${userid}`]
    }
    Hexapi(obj1).then((res) => {
      console.warn(res);
      if (res[""][0]) {
        setUserorigin(res[""][0].origin);
        setusertype(res[""][0].usertype);
        UserType = res[""][0].usertype;
        Origin = res[""][0].origin;

        if (res[""][0].currency == "inr" || res[""][0].currency == "INR") {
          PriceValue = true;
          setOverseasINR(true);
        } else {
          PriceValue = false;

          setOverseasINR(false);
        }
      }
    })
      .then(() => {
        let obj2 = {
          'query': `[dbo].[Seetech_Web_Proc_ProductMaster_SearchGetData]@UserID='{0}',@ProductID ='{1}'`,
          'queryArr': [`${userid}`, `${RecID}`]
        }
        Hexapi(obj2).then((res) => {
          console.log(res);

          let images = res[""][0]?.images;
          images = images.split(',');
          images = images.filter(item => !item.includes('default'))

          console.log(images);
          if (res[""][0].images != "") {
            setmainimage(`${images[0].replace('wp-content/',"")}`);
          } else {
            setmainimage("");

          }

          let price = null;

          if (Origin.toLowerCase() === "domestic") {
            if (UserType.toLowerCase() === "visitor") {
              price = `₹${Number(res[""][0]?.mrp).toFixed(2)}`;
            } else if (UserType.toLowerCase() === "retailer") {
              price = `₹${Number(res[""][0]?.mrp).toFixed(2)}`;
            } else {
              price = `₹${Number(res[""][0]?.wholesaleprice).toFixed(2)}`;
            }
          } else {
            if (PriceValue === true) {
              price = `₹ ${Number(res[""][0]?.overseasprice * res[""][0]?.conversionrate).toFixed(2)}`;
            } else {
              price = `$${Number(res[""][0]?.overseasprice).toFixed(2)}`;
            }
          }

          console.log(UserType, Origin)
          setProductPrice(price)

          setimagedata(images);
          setshowLoading(false);
          setproductdata(res[""][0]);
          document.title=res[""][0]['name']+' '+res[""][0]['suitableforpartreference']+' '+res[""][0]['suitableformodel']+'- seetechparts.com'

        });

      })
    // if (sessionStorage.getItem('origin').toLowerCase() == "overseas") {
    let obj = {
      'query': `[dbo].[Seetech_Web_Proc_Product_Details_GetData]@UserID='{0}',  @ProductID='{1}'`,
      'queryArr': [`${userid}`, `${RecID}`]
    }
    Hexapi(obj).then((res) => {
      console.log(res);
      if (res[""][0].moq) {
        setQuantityMoq(res[""][0].moq)
      }
      if (res[""][0].cart == 0) {
        setCartItems(true);
      }

      if (res[""][0].wishlist == 0) {
        setfavouriteItem(true);
      } else {
        setfavouriteItem(false);
      }

      if (res[""][0].qty) {
        setQuantityCart(res[""][0].qty)
        if (res[""][0].qty > 0) {
          setisItemAdded(true);
        }

      }

    });

    const handleWindowsize = () => {
      // console.log(window.innerWidth)
      setwindowwidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowsize);
    return () => {
      window.removeEventListener('resize', handleWindowsize)
    }

  }, [usertype])

 

  const zoomIn = (event) => {
    setPreviewVisible(true)
    var posX = event.nativeEvent.offsetX;;
    var posY = event.nativeEvent.offsetY;
    let image = `-${posX * 2.0}px -${posY * 2.0}px`;
    setzoomimage(image);
  }

  const zoomOut = () => {
    setPreviewVisible(false)
  }


  const favnotify = (text) =>
    toast.success(text, {
      position: toast.POSITION.TOP_CENTER, autoClose: 500
    });


  const cartnotify = (text) =>
    toast.success(text, {
      position: toast.POSITION.TOP_CENTER, autoClose: 500
    });

  const markfavourite = (Id) => {
    if (sessionStorage.getItem('userid') !== "" && sessionStorage.getItem('userid') != undefined) {
      let obj = {
        'query': `[dbo].[Seetech_Web_Proc_Wishlist_Add]@UserID='{0}',  @ProductID='{1}'`,
        'queryArr': [`${userid}`, `${Id}`]
      }
      Hexapi(obj).then((res) => {
        console.log(res);
        setfavouriteItem(false);
        favnotify("Item added to wishlist !")

      });
    }
    else {
      Swal.fire({
        // icon: 'error',
        // title: 'Oops...',
        text: 'Login please !',
      })
    }
  }

  const removefavourite = (Id) => {
    if (sessionStorage.getItem('userid') != "" && sessionStorage.getItem('userid') != undefined) {

      let obj = {
        'query': `[dbo].[Seetech_Web_Proc_Wishlist_Remove]@UserID='{0}',  @ProductID='{1}'`,
        'queryArr': [`${userid}`, `${Id}`]
      }
      Hexapi(obj).then((res) => {
        console.log(res);
        setfavouriteItem(true);
        favnotify("Item removed from wishlist !")
      });
    }
  }

  const AddtoCart = (mrp) => {
    // setCartItems(!CartItems)
    const qty = QuantityCart * productdata.moq;
    if (qty > 0) {
      console.log(mrp)
      const productprice = mrp.slice(1);
      let obj = {
        'query': `[dbo].[Seetech_Web_Proc_Cart_Add] @UserID='{0}',@ProductID='{1}',@Qty='{2}',@Price='{3}'`,
        'queryArr': [`${userid}`, `${productdata.recid}`, `${qty}`, `${productprice}`]
      }
      Hexapi(obj).then((res) => {
        console.log(res);
        cartnotify("Item Added to Cart!")
        if (isItemAdded == false) {
          dispatch({ type: 'CartItems', payload: { CartItems: TotalCartItems + 1 } });
          setisItemAdded(true);
        }
      });
    }
    else {
      Swal.fire({
        icon: 'info',
        text: 'Quantity cannot be negative',
        confirmButtonColor: '#f9b101',
      })
    }
  }

  const DeleteItemCart = (Id) => {
    setCartItems(!CartItems)
    setQuantity(1)
    let obj = {
      'query': `[dbo].[Seetech_Web_Proc_Cart_Remove] @UserID='{0}',@ProductID='{1}'`,
      'queryArr': [`${userid}`, `${RecID}`]
    }
    Hexapi(obj).then((res) => {
      console.log(res);
      cartnotify("Item Removed from Cart!")

    });
  }

  // const OpenQuotation = (image, value) => {
  //   let GST = 0;
  //   let price = 0;
  //   if (sessionStorage.getItem("origin") === "Domestic") {
  //     if (sessionStorage.getItem("usertype").toLowerCase() == "retailer") {
  //       price = Number(value.mrp).toFixed(0) - Number(value.mrp).toFixed(0) * 18 / 100;
  //       GST = Number(value.mrp).toFixed(0) * 18 / 100;
  //     } else if (sessionStorage.getItem("usertype").toLowerCase() == "wholesaler") {
  //       price = Number(value.mrp).toFixed(0)
  //       GST = Number(value.mrp).toFixed(0) * 18 / 100;
  //     }
  //   } else {
  //     price = value.overseasprice;
  //   }
  //   // console.warn(price,value);

  //   const arr = [
  //     {
  //       // Image: image,
  //       // description: value.description,
  //       // Qty: (value.qty == undefined || !value.qty ? 1 : value.qty),
  //       // price: price,
  //       // Gst: GST,
  //       // GSTpercentage: value.gst,
  //       // Totalprice: Number(value.mrp).toFixed(2),
  //       // hsnCode: value.hsncode,
  //       // modelReference: value.suitableforpartreference,
  //       // companyCode: value.companycode,
  //       // sku: value.sku,
  //       // productName: value.name,
  //       // pricetag: sessionStorage.getItem("origin") === "Domestic" ? "Rs" : "$",

  //       Image: image, // Make sure you have the correct image variable
  //       description: value.description,
  //       Qty: (value.qty == undefined || !value.qty ? 1 : value.qty),
  //       price: price,
  //       Gst: GST,
  //       GSTpercentage: value.gst,
  //       Totalprice: Number(value.mrp).toFixed(2),
  //       hsnCode: value.hsncode,
  //       modelReference: value.suitableforpartreference,
  //       companyCode: value.companycode,
  //       sku: value.sku,
  //       productName: value.name,
  //       pricetag: sessionStorage.getItem("origin") === "Domestic" ? "Rs" : "$",

  //       // pricetag: rowData.origin.toLowerCase() === "domestic" || Originvalue == "inr" ? "Rs" : "$",
  //       servicecharge: Number(value.servicecharge).toFixed(2),
  //       specialdiscount: Number(value.specialdiscount).toFixed(2),
  //       packingforward: Number(value.packingcharge).toFixed(2),
  //       // origin: rowData.origin.toLowerCase(),
  //     }
  //   ]

  //   console.warn(arr);
  //   let fd = new FormData();
  //   fd.append('dataArray', JSON.stringify(arr));

  //   console.warn(fd);
  //   axios({
  //     mode: 'cors',
  //     method: "POST",
  //     // headers: { "Content-Type": "application/json" },
  //     headers: { "Content-Type": "multipart/form-data" },
  //     url: `https://hexbss.xyz/testenvironment/seetechApiDomestic.php`,
  //     // url:`https://hexbss.xyz/crm/quote.php`,
  //     data: fd,
  //     responseType: 'arraybuffer',
  //   })
  //     .then((res) => {
  //       console.log(res);
  //       console.log(res.data);
  //       const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
  //       const pdfObjectURL = URL.createObjectURL(pdfBlob);
  //       setshowquotationpdf(true);
  //       setquotationpdffile(pdfObjectURL)

  //     })
  // }



  return (
    <>
      <div style={{ width: '95%', height: 'auto', margin: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }} >
          <Link to={`/shop`} style={{ textDecoration: 'none', color: 'black' }}>
            <ArrowBackIcon style={{ cursor: 'pointer' }} />
            <b style={{ cursor: 'pointer' }}>Back</b>
          </Link>
        </div>
        <div style={{ borderBottom: '2px solid rgb(208, 132, 12)', marginTop: '10px' }}>
          <div style={{ width: '100%', }}>
            <h3 style={{ paddingBottom: '0px' }}>Product Description</h3>
          </div>
        </div>

        {
          showLoading ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
              <h3>
                <Spinner animation='border' style={{ color: '#f9b101' }} />
              </h3>
            </div>
            :
            <>


              <div className='productsmain'>

                {/* Left Side */}
                <div style={windowidth < 800 ? { width: '100%', height: 'auto', paddingRight: '18px' } : { width: '50%', height: 'auto', paddingRight: '30px' }}>
                  <div className='tile' style={{ padding: '15px 40px', display: 'flex', justifyContent: 'center', overflow: 'hidden', gap: '20px' }}>
                    {/* <img src="https://seetechparts.com/wp-content/uploads/2022/03/JCB.webp" alt="" style={{ width: '320px', height: '280px', borderRadius: '10px' }}></img> */}

                    {/* <Carousel
                autoPlay={true} showIndicators={true} infiniteLoop={true} showStatus={false} showArrows={true} transitionTime={1000} showThumbs={false}>
                {
                  props.imagedata.map((image, index) => {
                    return (
                      <div className='firstcarousel' style={{ width: '100%', height: '100%' }} key={index}>
                        <img src={image} style={windowidth > 1050 ? { width: '300px ', height: '300px', margin: 'auto' } : {}}></img>

                      </div>
                    )
                  })
                }
              </Carousel> */}
                    <div style={{ width: '20%', cursor: 'pointer' }}>
                      {
                        imagedata.map((image, index) => {
                          return (
                            <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { setmainimage(`${image.replace('wp-content/',"")}`) }}>
                              <span style={{ border: '2px solid #e7e7e7', borderRadius: '7px' }}>
                                <img src={productdata.images != "" ? `${image.replace('wp-content/',"")} `: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt={productdata.suitableformodel + '-' + productdata.suitableforpartreference} className='sideimagepanel' ></img></span>
                            </div>
                          )
                        })
                      }
                    </div>
                    <div style={{ width: '80%' }}>

                      <img id="zoom1" src={mainimage != "" ? mainimage : `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt={productdata.suitableformodel + '-' + productdata.suitableforpartreference} className='ProductImage' style={{ margin: 'auto', cursor: 'zoom-in' }} onMouseMove={(e) => { zoomIn(e) }} onMouseOut={(e) => { zoomOut() }} ></img>

                    </div>
                  </div>

                  <div style={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'space-between', marginBottom: '25px' }}>
                    {
                      usertype.toLowerCase() == "visitor" ?
                        null
                        :
                        <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '10px 0' }}>
                          <div className='quantitystyle' style={{ cursor: 'pointer', width: '126px' }}>
                            <span>Quantity: </span>
                            {/* <select name="cars" id="cars" style={{ border: 'none', backgroundColor: '#f1f1f1' }} onChange={(e) => { setQuantity(e.target.value) }}>

                        {productdata && productdata.moq !== undefined ?
                          Quantitydata.map((s, i) => {
                            const Qtydata = (i + 1) * productdata.moq;
                            return (
                              <>
                                <option value={Qtydata} >{Qtydata} </option>
                              </>
                            )
                          })
                          :
                          <option value="1" >1</option>
                        }
                      </select> */}

                            <input type='text' name="cars" id="cars" value={QuantityCart} style={{ border: 'none', backgroundColor: '#f1f1f1', width: '45px' }}
                              onChange={(e) => { setQuantityCart(e.target.value) }}></input>

                          </div>
                        </div>
                    }
                    {
                      usertype.toLowerCase() == "visitor" ?
                        null
                        :
                        isItemAdded ?
                          <button className='productsbtnstyle' style={{ color: 'rgb(208, 132, 12)', backgroundColor: 'black', fontWeight: '700' }} onClick={() => { AddtoCart(ProductPrice) }}>Modify Cart</button>
                          :
                          <button className='productsbtnstyle' style={{ backgroundColor: 'rgb(208, 132, 12)' }} onClick={() => { AddtoCart(ProductPrice) }}>Add to Cart</button>
                    }
                  </div>
                </div>

                {/* Rightside */}
                {
                  previewVisible && windowidth > 800 && mainimage != "" &&
                  <div >
                    <div id="preview"
                      style={{
                        backgroundImage: `url(${mainimage})`,
                        backgroundPosition: zoomimage,
                        backgroundRepeat: ' no-repeat',
                        visibility: 'visible',
                        position: 'absolute',
                        boxShadow: ' 0 3px 5px #c1bdbd'
                      }}
                      onMouseMove={(e) => { zoomIn(e) }} ></div>
                  </div>
                }


                <div style={windowidth < 800 ? { width: '100%', height: 'auto', padding: '40px 0' } : { width: '50%', height: 'auto', padding: '40px 0' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h1 style={{ display: 'none' }}>{productdata.suitableforpartreference}</h1>
                    <h5 style={{ marginBottom: '0' }}>{productdata.name}</h5>
                    <div style={{ cursor: 'pointer' }} >
                      {
                        usertype.toLowerCase() == "visitor" ?
                          null
                          :
                          favouriteItem ?
                            <span onClick={() => { markfavourite(productdata.recid) }}>
                              <FavoriteBorderIcon />
                            </span>
                            :
                            <span onClick={() => { removefavourite(productdata.recid) }} >
                              <FavoriteIcon />
                            </span>
                      }
                    </div>
                  </div>
                  <div >
                    <span style={{ color: 'rgb(208, 132, 12)', fontWeight: 'bold' }}>SKU</span>
                  </div>
                  <div>
                    <span>Brand:  <b>{productdata.brand}</b> </span>
                  </div>
                  {
                    usertype == undefined || usertype == null || usertype.toLowerCase() == "visitor" ?
                    null
                    :
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '15px', fontWeight: '600', fontSize: '1.3rem' }}>
                      {ProductPrice}
                      <span style={{ textDecoration: 'line-through', fontSize: '16px', }}>
                        {
                          // usertype == undefined || usertype == null || usertype.toLowerCase() == "visitor" ?
                          //   null
                          //   :
                            Userorigin === "Domestic" ? (
                              usertype == "Wholesaler" &&
                              (
                                ` ₹${Number(productdata.mrp).toFixed(2)}`
                              )
                            ) : null
                        }

                      </span>
                      {/* <span style={{ fontSize: '28px', fontWeight: 'bold' }}>{productdata.mrp}</span>
                    <span style={{ textDecoration: 'line-through', fontSize: '20px', }}>MRP ₹14,000</span>l
                    <span className='discountstyle'>-26%</span> */}
                    </div>
                  }

                  <div style={{ margin: '10px 0', }}>
                    <span style={{ color: 'rgb(208, 132, 12)', fontWeight: 'bold' }}>In stock</span>
                  </div>
                  <div style={{ borderTop: '1px solid rgb(208, 132, 12)', display: 'flex', gap: '10px', alignItems: 'center', paddingTop: '15px', justifyContent: 'space-between' }}>
                    <div>
                      <span style={{ color: 'rgb(208, 132, 12)', fontSize: '17px' }}>Category: </span><span><b>{productdata.category}</b></span>
                    </div>
                    <div>
                      <span style={{ color: 'rgb(208, 132, 12)', fontSize: '17px' }}>Tags: </span><span><b>{productdata.suitableformodel}</b></span>
                    </div>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <h5 style={{ fontSize: '22px' }}>Description</h5>
                    <div>
                      <span style={{ color: '#817575', fontSize: '0.9rem' }}>{productdata.description}</span>
                    </div>
                  </div>
                </div>
              </div>


              {/* Additional Info table */}
              {
                usertype == undefined || usertype == null || usertype.toLowerCase() == "visitor" ?
                  null
                  :
                  <div style={{ margin: '40px 0px' }}>
                    <h4>Additional Information</h4>
                    <table class="Product_table">
                      <tbody>
                        {
                          productdata.weight != "" ?
                            <tr >
                              <th className='tableheadingstyle'>Box Weight (kg)</th>
                              <td className='tableheadingstyle'>{productdata.weight}</td>
                            </tr>
                            :
                            null
                        }
                        {
                          productdata.length != "" ?
                            <tr>
                              <th className='tableheadingstyle'>Box Dimensions</th>
                              <td className='tableheadingstyle'>{Number(productdata.length).toFixed(2) + " * " + Number(productdata.weight).toFixed(2) + " * " + Number(productdata.height).toFixed(2) + " cm"}</td>
                            </tr>
                            :
                            null
                        }

                        {
                          productdata.length != "" ?
                            <tr >
                              <th className='tableheadingstyle'>Suitable for Model</th>
                              <td className='tableheadingstyle'>{productdata.suitableformodel}</td>
                            </tr>
                            :
                            null
                        }

                        {
                          productdata.length != "" ?
                            <tr >
                              <th className='tableheadingstyle'>Suitable for Sub Model</th>
                              <td className='tableheadingstyle'>{productdata.suitableforsubmodel}</td>
                            </tr>
                            :
                            null
                        }

                        {
                          productdata.length != "" ?
                            null
                            :
                            <tr>
                              <th className='tableheadingstyle'>Suitable for Part Reference</th>
                              <td className='tableheadingstyle'>{productdata.suitableforpartreference}</td>
                            </tr>
                        }

                        {
                          productdata.weight != "" ?
                            <tr >
                              <th className='tableheadingstyle'>Company Code</th>
                              <td className='tableheadingstyle'>{productdata.companycode}</td>
                            </tr>
                            :
                            ""
                        }
                        <tr >
                          <th className='tableheadingstyle'>GST (%)</th>
                          <td className='tableheadingstyle'>{productdata.gst}</td>
                        </tr>
                        <tr >
                          <th className='tableheadingstyle'>Make</th>
                          <td className='tableheadingstyle'>{productdata.make}</td>
                        </tr>
                        <tr >
                          <th className='tableheadingstyle'>Product Condition</th>
                          <td className='tableheadingstyle'>{productdata.productcondition}</td>
                        </tr>
                        <tr >
                          <th className='tableheadingstyle'>Brand</th>
                          <td className='tableheadingstyle'>{productdata.brand}</td>
                        </tr>
                        {
                          productdata.length != "" ?
                            <tr >
                              <th className='tableheadingstyle'>Country of Origin</th>
                              <td className='tableheadingstyle'>{productdata.countryoforigin}</td>
                            </tr>
                            :
                            null
                        }
                        {
                          sessionStorage.getItem("usertype") == "Admin" &&
                          <>
                            <tr >
                              <th className='tableheadingstyle'>Rack Section</th>
                              <td className='tableheadingstyle'>{productdata.racksection}</td>
                            </tr>
                            <tr >
                              <th className='tableheadingstyle'>Rack Code</th>
                              <td className='tableheadingstyle'>{productdata.rackcode}</td>
                            </tr>
                            <tr >
                              <th className='tableheadingstyle'>Pouch/ Tube Code</th>
                              <td className='tableheadingstyle'>{`${productdata["pouch/tubecode"]}`}</td>
                            </tr>
                          </>

                        }
                      </tbody>
                    </table>

                  </div>
              }
            </>
        }

        {/* Product deleviery */}
        {/* <div className='productdetails'>
          <div className='productdescstyle'>
            <LocalShippingIcon style={{ color: 'rgb(208, 132, 12)', height: '30px', width: '30px' }} />
            <span>Dispatch in 2 days</span>
          </div>
          <div className='productdescstyle'>
            <SyncDisabledIcon style={{ color: 'rgb(208, 132, 12)', height: '30px', width: '30px' }} />
            <span>Not refundable</span>
          </div>
          <div className='productdescstyle'>
            <ReceiptLongIcon style={{ color: 'rgb(208, 132, 12)', height: '30px', width: '30px' }} />
            <span>GST Invoice</span>
          </div>
        </div> */}

        {/* Related Products */}
        {/* <div style={{ width: '100%', height: 'auto' }}>
          <div style={{ borderBottom: '2px solid rgb(208, 132, 12)' }}>
            <span style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Related </span><span style={{ fontSize: '2.5rem', fontWeight: 'bold', color: 'rgb(208, 132, 12)' }}>Products</span>
          </div>
          <div className='relatedproductsstyle'>
            {
              Quantitydata.map((s, index) => {
                return (
                  <>
                    <div className='relatedproductsitems'>
                      <div style={{ width: '100%', height: 'auto' }}>
                        <img src="https://seetechparts.com/wp-content/uploads/2022/03/JCB.webp" alt="" style={{ width: '100%', height: '100%' }}></img>
                      </div>
                      <div style={{ padding: '15px' }}>
                        <div style={{ marginBottom: '10px' }}>JCB Spare Parts</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                          <div style={{ display: 'flex', gap: '10px', }}>
                            <span style={{ fontWeight: 'bold' }}>₹8,000</span>
                            <span style={{ textDecoration: 'line-through' }}>MRP ₹14,000</span>
                          </div>
                          <div>
                            <span className='discountstyle'>-26%</span>
                          </div>
                        </div>
                        <div>
                          <span>Dispatch within <b>5 days</b> </span>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }

          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className='productviewmore' style={{ backgroundColor: 'rgb(208, 132, 12)' }}>View More</button>
          </div>
        </div> */}

      </div>
      <Modal
        fullscreen={true}
        size="xl"
        scrollable={true}
        // fullscreen
        show={showquotationpdf}
        // onHide={showquotationpdf}
        keyboard={false}
        backdrop="static"
        style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
        centered >
        <Modal.Header closeButton onClick={() => { setshowquotationpdf(false) }}>
          <Modal.Title>Download PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'center', height: '98%' }}>
            <iframe
              src={quotationpdffile}
              title="Download pdf"
              // src={`data:application/pdf;base64,${btoa(String.fromCharCode(...new Uint8Array(this.state.quotationpdffile)))}`}
              style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}></iframe>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
      // style={windowidth < 450 ? { width: '200px', fontSize: '0.7rem', } : null} 
      />


    </>
  )
}



export default Products