import React, { useEffect , useState} from 'react';
import './Warranty.css'
import { useDispatch } from 'react-redux';

const Warranty = () => {
    const dispatch = useDispatch();
    const [windowidth, setwindowwidth] = useState(window.innerWidth);

    useEffect(() => {
        document.title = 'JCB Spare Parts - Earth Moving Equipment Manufacturer | Seetech Parts, Faridabad'
        dispatch({ type: 'SaveRoutes', payload: { route: "warranty" } });

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const handleWindowsize = () => {
            // console.log(window.innerWidth)
            setwindowwidth(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowsize);
        return () => {
            window.removeEventListener('resize', handleWindowsize)
        }
    }, [])

    return (
        <>
            <div style={ { width: '90%', height: 'auto', margin: 'auto', marginTop: '1.5rem', marginBottom: '5rem' }}>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <h3 style={{ borderBottom: '2px solid #f9b101' }}>Warranty policy</h3>
                </div>
                <h1 style={{ paddingTop: '3pt', paddingLeft: '137pt', textIndent: '0pt', textAlign: 'center' }}>STANDARD WARRANTY POLICY</h1>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p className="s1" style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>NO IMPLIED WARRANTY OR OTHER
                    REPRESENTATION</p>
                <p style={{ paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify' }}>Where
                    permitted by law, neither SEETECH EARTHMOVING EQUIPMENTS PRIVATE LIMITED (herein after referred as Seetech) nor
                    any company, affiliated with it makes any warranties, representations or promises, express or implied, as to the
                    quality, performance, and freedom from defect of Repair Products, other than those set forth on this page, and
                    NO IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS IS MADE.</p>
                <p style={{ paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify' }}>The limited
                    warranty coverage described below is provided by Seetech to the original purchasers of component. Original
                    Purchaser means customer of Seetech to whom Seetech has raised invoice and received products from Seetech. Under
                    this warranty, Seetech will replace or repair, at its option, any parts, as delivered to the original purchaser
                    are defective in material or workmanship. Parts warranty coverage applies only to purchases made from Seetech
                    and on Aftermarket products of Seetech Brand name only. However, if two qualities of the same product
                    (manufactured by same or different manufacturer(s)) are offered by Seetech then warranty applies to premium
                    quality product only. OEM (including OEM suppliers) products warranty will be as per OEM (including OEM
                    suppliers) warranty only.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>PERIOD OF WARRANTY</h1>
                <p style={{ paddingTop: '9pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify' }}>Spare Parts
                    Sold by Seetech are warranted to be free from defects in material and workmanship under normal use and service
                    for a period of 6 months or breaks under normal use due to defective material and workmanship for a period of 6
                    months from the date of delivery made by Seetech to its customer.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>ITEMS NOT COVERED</h1>
                <p style={{ paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify' }}>Any
                    compensation for damages to the original purchaser shall not exceed the invoice value (of the claimed products)
                    raised by Seetech to original purchaser, of the claimed products. Therefore, Seetech is NOT responsible for the
                    following:</p>
                <ol id="l1">
                    <li data-list-text="a)">
                        <p style={{ paddingTop: '7pt', paddingLeft: '26pt', textIndent: '-21pt', textAlign: 'left' }}>Labour charges.</p>
                    </li>
                    <li data-list-text="b)">
                        <p style={{ paddingTop: '1pt', paddingLeft: '26pt', textIndent: '-21pt', textAlign: 'left' }}>Transporting the product
                            to and from the place where the service is performed.</p>
                    </li>
                    <li data-list-text="c)">
                        <p style={{ paddingTop: '1pt', paddingLeft: '26pt', textIndent: '-21pt', textAlign: 'left' }}>Transportation and
                            packing charges to the original purchaser/ end user.</p>
                    </li>
                    <li data-list-text="d)">
                        <p style={{ paddingTop: '1pt', paddingLeft: '26pt', textIndent: '-21pt', textAlign: 'left' }}>Tax/Duties and similar
                            paid by the original purchaser/ end user.</p>
                    </li>
                    <li data-list-text="e)">
                        <p style={{ paddingTop: '1pt', paddingLeft: '26pt', textIndent: '-21pt', lineHeight: '108%', textAlign: 'left' }}>Any
                            defect in a non-covered component, or damage to and/or failure of a covered component caused by a defect
                            in a non-covered component.</p>
                    </li>
                    <li data-list-text="f)">
                        <p style={{ paddingTop: '3pt', paddingLeft: '26pt', textIndent: '-21pt', lineHeight: '108%', textAlign: 'justify' }}>
                            Defect that is due to normal wear and tear, improper maintenance, excessive wear and tear caused by use
                            in unusual circumstances; misapplication; neglect; unauthorized repair; abnormal use; accident;
                            malicious intent or abuse; mishandling; improper installation or usages; natural disasters; power
                            surges; exposure to liquids or chemicals; faulty service or modifications or failure to comply with the
                            relevant directions for use and overloading. It shall not cover any defect which results from the
                            modification of a Product by the Buyer’s customer or the Product’s end user, subject to the proviso that
                            the mere attachment or incorporation of a Product to or in a larger entity by the Buyer or a third party
                            without the Sellers’ consent shall not be deemed to constitute a modification.</p>
                    </li>
                </ol>
                <h1 style={{ paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>UNAPPROVED SERVICE OR MODIFICATION
                </h1>
                <p style={{ paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>Seetech is relieved of its
                    obligation under this limited warranty if:</p>
                <ol id="l2">
                    <li data-list-text="a)">
                        <p style={{ paddingTop: '9pt', paddingLeft: '19pt', textIndent: '-14pt', textAlign: 'left' }}>The user has not
                            practiced good maintenance and service procedure; or</p>
                    </li>
                    <li data-list-text="b)">
                        <p style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '-14pt', textAlign: 'left' }}>The product is modified
                            or altered in ways not approved by Seetech; or</p>
                    </li>
                    <li data-list-text="c)">
                        <p style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '-14pt', lineHeight: '108%', textAlign: 'left' }}>Any
                            product has been disassembled prior to return to Seetech for warranty inspection.</p>
                    </li>
                </ol>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left' }}>Seetech will replace or repair, as
                    it elects, such defective product, provided the defect is reported to Seetech within 12 days from when it first
                    occurred and still within the period of warranty.</p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <h1 style={{ paddingLeft: '5pt', textIndent: '0pt', textAlign: 'left' }}>OBTAINING WARRANTY</h1>
                <p style={{ paddingTop: '8pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'left' }}>When making such
                    a request, the purchaser must present evidence of the product’s delivery date,</p>
                <p style={{ paddingTop: '7pt', paddingLeft: '5pt', textIndent: '0pt', lineHeight: '110%', textAlign: 'left' }}>Failed parts
                    become Seetech’s property and kept for 90 days or until a Seetech disposition is given, whichever occurs first.
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                <p style={{ paddingLeft: '5pt', textIndent: '0pt', lineHeight: '108%', textAlign: 'justify' }}>IN NO EVENT WILL THE DEALER,
                    SEETECH OR ANY COMPANY AFFILIATED WITH SEETECH, BE LIABLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING
                    BT NOT LIMITED TO, LOSS OF PROFITS, RENTAL OF SUBSTITUTE EQUIPMENT OR OTHER COMMERCIAL LOSS. IN THE EVENT ABOVE,
                    LIMITED WARRANTY IS DEEMED TO HAVE FAILED OF ITS ESSENTIAL PURPOSE, ORIGINAL PURCHASER’S BACKUP REMEDY IS
                    LIMITED TO LOWER OF INVOICE VALUE OR REIMBURSEMENT OF THE PRICE ACTUALLY PAID FOR THE SUBJECT PARTS ONLY.</p>
            </div>

        </>
    )
}

export default Warranty